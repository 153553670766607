/**
* ==========================================================================
* _element.input-telephone.scss
* Styles for multi-part phone field
* This pattern relies heavily on JavaScript for complete functionality,
* see Polaris for more information.
* ==========================================================================
*/

select.ti-phone-country-code {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 4rem;
}

input[type='tel'].ti-phone-number {
  flex-grow: 1;
  min-width: 1rem;
  width: auto;
}

input.ti-phone-extension {
  flex-grow: 0;
  max-width: 5rem;
}
