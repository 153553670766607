/**
* ==========================================================================
* _object.leftnav.scss
* This is the left nav component
* ==========================================================================
*/

/* stylelint-disable */

.ti_left-nav {
  position: relative;
  z-index: 2;
  float: left;
  width: 260px;
  margin: 0 0 2rem 0;
  padding: 2rem 2rem;
  border: 1px solid $color-grey2;
  border-width: 1px 1px 1px 0;
  background: $color-grey1;
}

.ti_left-nav.collapsed {
  width: auto;
  min-width: auto;
  padding: 0;

  .ti_left-nav-collapse {
    display: none;
  }

  .ti_left-nav-expand {
    display: block;
  }
}

.ti_left-nav.is-collapsed {
  height: auto;
  padding: 0;
  width: auto;
}

.ti_left-nav.is-collapsed .ti_left-nav-collapse {
  display: none;
  margin-left: -17px;
  margin-top: 0;
  width: auto;
}
.ti_left-nav.is-collapsed .ti_left-nav-expand {
  display: block;
}

.ti_left-nav.is-collapsed .ti_left-nav-container {
  display: none;
}

.ti_left-nav-collapse {
  display: block;
  position: absolute;
  height: 24px;
  margin-left: 0;
  right: 10px;
  top: 10px;
  width: 24px;
}

.ti_left-nav-expand {
  display: none;
  padding: 40px 0;
  text-align: center;
  width: 40px;
}

.ti_left-nav-expand span {
  display: block;
  font-size: 12px;
}


.ti_left-nav {
  h2 {
    color: $color-grey7;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    background-image: none;
    margin: 0;
    padding: 0;
  }

  .ti-nav-title {
    margin: 0 0 1rem 0;
    padding: 0;
    color: $color-grey7;
    font-size: 1.2em;
  }

  .navItem {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    border-top: 1px dotted $color-grey2;
    background: none;
    color: $color-grey8;
    display: block;

    &.is-active {
      color: $color-red5;
    }
  } 

  .ti-nav-level1 > .navItem {
    font-weight: $base-font-weight-bold;
  }

  .ti-nav-level2 > .navItem {
    padding-left: 1em;
  }

  .ti-nav-level3 > .navItem {
    padding-left: 3em;
    position: relative;

    &::before {
      content: "–"; //n-dash
      position: absolute;
      left: 2em;
      top: .5em;
    }
  }

  .ti-nav-level4 > .navItem {
    padding-left: 3.75em;
    position: relative;
    font-style: italic;

    &::before {
      content: "-"; //dash
      position: absolute;
      left: 3em;
      top: .5em;
    }
  }  
  
}

.ti_apps-nav {
  .ti_matched-message {
    display: inline-block;
  }

  .filter {
    width: 100%;
    padding-right: 2rem;
    background-image: url("https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-actions/filter.svg");
    background-size: 24px;
    background-position: calc(100% - 0.25rem) -70px;
    background-repeat: no-repeat;
  }

  .result-count {
    position: relative;
    margin: 1em 0;
  }

  #fl_resetLink {
    position: absolute;
    right: 0;
    bottom: 0;
    color: $color-red5;
  }

  .highlight {
    background: $color-yellow2;
  }

  .ti_left-nav-container ul.active {
      display: block;
  }

  .apps-sector {
    &.ti-nav-level1 > .navItem {
      font-weight: normal;
      padding-left: 1em;
    }
    &.ti-nav-level2 > .navItem {
      padding-left: 2em;
    }
  }
}

.productTreeSupplementalLinks,
.leftNavigationAuthored {
  .ti_left-nav-container > ul:first-child > li:first-child > .navItem {
    border-top: none;
    margin-top: 1rem;
  }
}

.productTreeSupplementalLinks,
.leftNavigationAuthored {
  .ti-nav-title {
      margin-top: 2rem;
  }
}

.leftNavigationAuthored,
.is-empty-tree .productTreeSupplementalLinks {
  &:first-child {
    .ti-nav-title {
      margin-top: 0;
    }
    .ti_left-nav-container > ul:first-child > li:first-child > .navItem {
      margin-top: 0;
    } 
  }
}
/* stylelint-enable */
