/**
* ==========================================================================
* _object.hero.scss
* corp hero component
* (todo: cleanup - kayin)
* ==========================================================================
*/

/* stylelint-disable */

/* corp hero component styles */

.aboutTI .ti_productTree-container h6 {
  display: none !important;
}

.aboutTI .leftNavigationAuthored {
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
}

.aboutTI .ti_productTree .leftNavigationAuthored:last-child {
  border-bottom: 1px dotted #e0e0f4 !important;
}

/* hero and contents */

.ti-hero {
  position: relative;
  overflow: hidden;
  margin-bottom: 3rem;
}

.ti-hero h1,
.ti-hero h2 {
  position: relative;
  display: inline-block;
  padding-bottom: 18px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.ti-hero h1 {
  font-size: 2.7em;
  margin-bottom: 0;
}

.ti-hero h2 {
  font-size: 2.2em;
}

.ti-hero h1:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2em;
  content: ' ';
  border-bottom: 1px solid #cc0000;
}

.ti-hero p {
  color: #000000;
  font-size: 1.18em;
  font-weight: 300;
}

.ti-hero .ti-tagline {
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 1.5em;
}

.ti-hero .ti-hero-top {
  padding: 20px 40px 32px;
}

.ti-hero .ti-hero-bottom {
  padding: 0 40px 32px;
}

.ti-hero-bottom .ti-cta {
  display: inline-block;
  margin-top: 36px;
}

.ti-hero-bottom .ti-cta + .ti-cta {
  margin-left: 36px;
}

.ti-hero .ti-overlay {
  position: relative;
  z-index: 10;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.85);
}

.ti-hero [class^='ti-position'] {
  position: absolute;
  width: 50%;
}

.ti-hero .ti-position-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.ti-hero .ti-position-right {
  top: 50%;
  right: 0;
   transform: translateY(-50%);
}

.ti-hero .ti-cover.ti-blur {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(5px);
}

.ti-hero .ti-cover {
  position: relative;
}

.ti-hero .ti-cover img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* overview component */

.aboutTI .overview {
  text-align: left;
}

.aboutTI .overview h3 {
  margin-bottom: 32px;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.aboutTI .overview h3 a {
  position: relative;
  /*display: inline;*/
  display: inline-block;
  padding-bottom: 16px;
}

.aboutTI .overview h3 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2em;
  content: ' ';
  border-bottom: 1px solid #cc0000;
}

.aboutTI .overview h3 a:hover {
  text-decoration: none;
}

.aboutTI .overview ul {
  display: none !important;
  border: 0;
}

.aboutTI .overview ul li {
  border: 0;
}

.aboutTI .overview ul li a {
  color: #cc0000;
}

.aboutTI .overview .ti_box.mod-style1 {
  padding: 20px 20px 40px 20px;
  border: 1px solid #e0e0f4 !important;
  background: 0 !important;
  box-shadow: 0 0 0 !important;
}

.aboutTI .ti-padding {
  padding: 20px 40px;
  box-shadow: 0 0 1px $color-black;
}

.aboutTI .overview a.button.btnD {
  padding: 0 12px 0 0;
  border: 0;
  background: url('https://www.ti.com/etc/designs/ti/images/icons/iconMore.png') no-repeat 100% 60%;
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/iconMore.svg');
}

.aboutTI h3.ti_sectionTitle {
  margin-bottom: 2.5rem;
}

.aboutTI .ti_sectionTitle p {
  position: relative;
  display: inline;
  padding-bottom: 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2em;
  font-weight: 300;
}

.aboutTI .ti_sectionTitle p:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2em;
  content: ' ';
  border-bottom: 1px solid #cc0000;
}

.aboutTI .ti_chapterNavContainer {
  position: relative;
}

.aboutTI .ti_chapterNav {
  width: 100%;
}

/* stylelint-enable */
