/**
* --------------------------------------------------------------------------
* _element.sectiontitle.scss
* Section title style
* --------------------------------------------------------------------------
*/

.ti_sectionTitle {
  margin-bottom: 2rem;
  padding-top: 0;
}

.ti_sectionTitle > p {
  margin: 0;
}
