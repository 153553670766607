/**
* ==========================================================================
* _element.searchbutton.scss
* This is the search field used on cross reference search component
* ==========================================================================
*/

%ti-search-button {
  width: 30px;
  height: 30px;
  margin: 0 0 0 -34px;
  padding: 0;
  cursor: pointer;
  vertical-align: bottom;
  border: 1px solid $color-grey3;
  border-left: 0;
  font-size: 0;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 3px 2px;
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-actions/search.svg');
  background-color: transparent;
  transition: background-color $animation-timing-selectionControls linear;

  &:hover,
  &:focus {
    background-color: $color-grey2;
  }
}

.search-button, // legacy name
.ti_p-input-searchButton {
  @extend %ti-search-button;
}

/* legacy naming */
.ti_searchButton-icon {
  @extend %ti-search-button;

  display: inline-block;
}

.ti_searchButton {
  display: none;
}

.ti_searchField.mod-crossref-search {
  width: auto;
  min-width: 40em;
  max-width: 100%;
}
