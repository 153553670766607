/**
* ==========================================================================
* _element.languageselect.scss
* This is the language select dropdown box
* ==========================================================================
*/

.ti_language-select {
  position: absolute;
  left: $page-min-width;
  transform: translate(-100%);
  margin-bottom: 1rem;
}
