/**
* ==========================================================================
* _component.chapternav.scss
* This is the chapter navigation component
* ==========================================================================
*/

.ti_chapterNavContainer {
  height: 4rem;
  margin-bottom: 3rem;
}

.ti_chapterNav {
  background: $color-grey1;
}

.ti_chapterNav.is-sticky {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: $global-boxShadow-4;
}

.ti_chapterNav:blank {
  display: none;
}

.ti_chapterNav-list {
  display: flex;
  margin: 0;
  padding: 0 1rem;
}

.ti_chapterNav-list:blank {
  display: none;
}

.ti_chapterNav-item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  background: 0;
  flex: 0 1 auto;
}

.ti_chapterNav-item::after {
  margin: 0 1rem;
  content: '|';
  color: $color-grey3;
}

.ti_chapterNav-item:last-child::after {
  display: none;
}

.ti_chapterNav-link {
  overflow: hidden;
  max-width: 250px;
  padding: 1.5rem 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1em;
}

.ti_chapterNav-item.is-active .ti_chapterNav-link {
  padding: 1.5rem 0 1.4rem 0;
  color: $color-red5;
  border-bottom: 2px solid $color-red5;
  font-weight: $base-font-weight-bold;
}

.ti_chapterNav-item.is-active .ti_chapterNav-link:hover {
  cursor: default;
  text-decoration: none;
}
