/**
* ==========================================================================
* _fonts.global.scss
* This file contains global font declarations
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Font face declarations
* --------------------------------------------------------------------------
*/

/* Note: font loading is currently handled in header.js using webfontloader
   the following is only for reference */

/* font face format example */
// @font-face {
//   font-family: 'fontname';
//   font-style: normal;
//   font-weight: $font-weight;
//   src: url(x.eot); /* IE9 Compat Modes */
//   src:
//     local('Font Name'), local('FontName'), /* Local font names */
//     url(x.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
//     url(x.woff2) format('woff2'), /* Super Modern Browsers */
//     url(x.woff) format('woff'), /* Pretty Modern Browsers */
//     url(x.ttf) format('truetype'), /* Safari, Android, iOS */
//     url(x.svg#svgFontName) format('svg'); /* Legacy iOS */
// }

// /* Open Sans, normal, latin */
// @font-face {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 400;
//   src:
//     local('Open Sans'),
//     local('OpenSans'),
//     url('https://www.ti.com/assets/fonts/ff_pc/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff') format('woff');
// }

// /* Open Sans, semibold, latin */
// @font-face {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 600;
//   src:
//     local('Open Sans Semibold'),
//     local('OpenSans-Semibold'),
//     url('https://www.ti.com/assets/fonts/ff_pc/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff') format('woff');
// }

// /* Roboto, normal, latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   src:
//     local('Roboto'),
//     local('Roboto-Regular'),
//     url('https://www.ti.com/assets/fonts/ff_pc/2UX7WLTfW3W8TclTUvlFyQ.woff') format('woff');
// }

// /* Roboto, semibold, latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   src:
//     local('Roboto Medium'),
//     local('Roboto-Medium'),
//     url('https://www.ti.com/assets/fonts/ff_pc/RxZJdnzeo3R5zSexge8UUT8E0i7KZn-EPnyo3HZu7kw.woff') format('woff');
// }

/**
* --------------------------------------------------------------------------
* Base font size and styles
* --------------------------------------------------------------------------
*/

html {
  font-size: $root-font-size;
}

body {
  color: $color-grey7;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
}

body, input, select, textarea, table, button {
  font-family: $base-font-family;
}

h1, h2, h3, h4, h5, h6, b, strong {
  font-weight: $base-font-weight-bold;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 1em;
}

/**
* --------------------------------------------------------------------------
* Language related font families
* --------------------------------------------------------------------------
*/

/* China */
html.zh-cn {

  body, input, select, textarea, table, button {
    font-family: $base-font-family-cn;
    letter-spacing: 0.1em;
  }
}

html.zh-cn .sub#sub_language {
  letter-spacing: normal;
}


/* Japan */
html.ja-jp {

  body, input, select, textarea, table, button {
    font-family: $base-font-family-jp;
  }
}

/* Korea */
html.ko-kr {

  body, input, select, textarea, table, button {
    font-family: $base-font-family-ko;
  }
}

/**
* --------------------------------------------------------------------------
* Icon fonts
* legacy!
* To be sunsetted
* --------------------------------------------------------------------------
*/

/* stylelint-disable */

@font-face {
  font-family: 'Material Icons', sans-serif;
  font-weight: $base-font-weight;
  font-style: normal;
  src: url('https://www.ti.com/etc/designs/ti/fonts/MaterialIcons-Regular.eot'); /* IE9 Compat Modes */
  src:
    // IE6-IE8
    url('https://www.ti.com/etc/designs/ti/fonts/MaterialIcons-Regular.eot#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    url('https://www.ti.com/etc/designs/ti/fonts/MaterialIcons-Regular.woff2') format('woff2'),
    // Pretty Modern Browsers
    url('https://www.ti.com/etc/designs/ti/fonts/MaterialIcons-Regular.woff') format('woff');
    // Safari, Android, iOS
    // url(MaterialIcons-Regular.ttf) format('truetype'),
    // Legacy iOS
    // url(MaterialIcons-Regular.svg#svgFontName) format('svg');
}

@font-face {
  font-family: 'ti-icons', sans-serif;
  font-weight: $base-font-weight;
  font-style: normal;
  //src: url('https://www.ti.com/etc/designs/ti/fonts/ti-icons.eot?qm906k'); // IE9 Compat Modes */
  src:
    // IE6-IE8
    //url('https://www.ti.com/etc/designs/ti/fonts/ti-icons.eot?#iefix') format('embedded-opentype'),
    // Super Modern Browsers
    // url('https://www.ti.com/etc/designs/ti/fonts/ti-icons.woff2') format('woff2'),
    // Pretty Modern Browsers
    url('https://www.ti.com/etc/designs/ti/fonts/ti-icons.woff?qm906k') format('woff');
    // Safari, Android, iOS
    // url(/etc/designs/ti/fonts/ti-icons.ttf) format('truetype'),
    // Legacy iOS
    // url(/etc/designs/ti/fonts/ti-icons.svg#svgFontName) format('svg');
}

*::before {
  display: inline-block;
  white-space: nowrap;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  font-family: 'Material Icons', sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  line-height: initial; // was "1" (kayin)
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  direction: ltr;
  font-feature-settings: 'liga';
}

/* stylelint-enable */
