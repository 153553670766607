/**
* ==========================================================================
* _generic.animations.scss
* CSS animations
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Animation helper classes
* use the prefix .a-
* --------------------------------------------------------------------------
*/

// Helper classes
.a-decelerate, .a-ease-out {
  transition: all, $animation-easing-decelerate, $animation-timing-enter;
}

.a-accelerate {
  transition: all, $animation-easing-accelerate, $animation-timing-exit;
}

.a-smooth {
  transition: all, $animation-easing-smooth, $animation-timing-enter;
}

// Fades
.a-fade-in {
  animation: fadeIn $animation-timing-fadeIn $animation-easing-decelerate;
}

.a-fade-out {
  animation: fadeOut $animation-timing-fadeOut $animation-easing-accelerate;
}

// Side-menu
.a-side-menu-open {
  animation: menu-enter-view $animation-timing-enter $animation-easing-decelerate;
}

.a-side-menu-close {
  animation: menu-exit-view $animation-timing-exit $animation-easing-accelerate;
}

// Slides
.a-slide-enter-up {
  animation:
    slideUp $animation-timing-enter $animation-easing-decelerate,
    fadeIn $animation-timing-fadeIn;
}

.a-slide-exit-down {
  animation:
    slideDown $animation-timing-exit $animation-easing-accelerate,
    fadeOut $animation-timing-exit;
}

.a-slide-enter-right {
  animation:
    slideRight $animation-timing-enter $animation-easing-decelerate,
    fadeIn $animation-timing-enter;
}

.a-slide-exit-left {
  animation:
    slideLeft $animation-timing-exit $animation-easing-accelerate,
    fadeOut $animation-timing-exit;
}

/**
* --------------------------------------------------------------------------
* Animation Keyframes
* --------------------------------------------------------------------------
*/

// input element highlight
@keyframes focus-highlight {
  from {
    box-shadow: 0 0 0 3px rgba($color-teal3, 1);
  }

  to {
    box-shadow: 0 0 0 3px rgba($color-teal3, 0.25);
  }
}

// toggle button highlight
@keyframes focus-btn {
  from {
    width: 75%;
    padding-top: 65%;
  }

  70% {
    width: 85%;
    padding-top: 75%;
  }

  to {
    width: 75%;
    padding-top: 65%;
  }
}

// Fade In
@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

// Fade Out
@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1;
  }

  99% {
    display: block;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

// Slide Up
@keyframes slideUp {
  0% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
}

// Slide Down
@keyframes slideDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(15px);
  }
}

// Slide Right
@keyframes slideRight {
  0% {
    transform: translateX(-15px);
  }

  100% {
    transform: translateX(0);
  }
}

// Slide Left
@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-15px);
  }
}

// Side Drawer Enter Stage
@keyframes menu-enter-view {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

// Slide Right
@keyframes menu-exit-view {
  0% {
    transform: translateX(0);
    display: block;
  }

  99% {
    display: block;
  }

  100% {
    transform: translateX(-100%);
    display: none;
  }
}
