/**
* --------------------------------------------------------------------------
* _element.ctalink.scss
* Common cta link element
* --------------------------------------------------------------------------
*/

.ti_cta,
.ti_section-cta {
  margin-bottom: 1em;
}

// new inline svg icon
.ti_section-cta {
  background: none;
  padding: 0;
}

.ti_section-cta .ti_icon {
  vertical-align: top;
  margin-top: 1px;
}
