/**
* ==========================================================================
* _object.m.mobileswitch.scss
* Switch to mobile site banner
* ==========================================================================
*/

.ti_m-mobileSwitch {
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 1rem 0 2rem;
}

.ti_m-mobileSwitch.is-hidden {
  display: none;
}

.ti_m-mobileSwitch a {
  color: #999999;
  text-decoration: none;
  font-size: 1rem;
  line-height: 50px;
}

.ti_m-mobileSwitch a:hover {
  text-decoration: none;
}

.ti_m-mobileSwitch .ti_m-mobileSwitch-link {
  flex: 1;
}

.ti_m-mobileSwitch .ti_m-mobileSwitch-close {
  width: 24px;
  margin-left: 4rem;
  font-size: 2rem;
  text-align: center;
}
