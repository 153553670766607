/**
* ==========================================================================
* _element.ligatureicon.scss
*
* This is a word replacing ligature icon, using icon font
* legacy!
* ==========================================================================
*/

.icon-wordreplace {
  position: relative;
  right: 9999px;
  display: inline-block;
  min-width: 1.2em;
  max-width: 1.2em;
  padding: 0 1.2em;
}

.icon-wordreplace::after {
  position: absolute;
  right: -9999px;
  bottom: 0;
  overflow: visible;
  width: 100%;
  text-align: center;
  font-family: 'Material Icons', sans-serif;
  font-size: 1.5em;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

a.icon-wordreplace::after {
  color: $color-teal5;
}

a.icon-wordreplace:hover::after {
  color: $color-red5;
}

.icon-check::after {
  content: '\e86c';
}

.icon-dash::after {
  content: '\e15b';
}

.icon-plus::after {
  content: '\e147';
}

.icon-minus::after {
  content: '\e15c';
}

.icon-delete::after {
  content: '\e872';
}

.icon-search::after {
  content: '\e8b6';
}

.icon-edit::after {
  content: '\e3c9';
}

.icon-expandMore::after {
  content: '\e5ce';
}

.icon-expandLess::after {
  content: '\e5cf';
}

.icon-colorteal {
  color: $color-teal6;
}

.icon-colorgrey {
  color: $color-grey7;
}

.icon-colorred {
  color: $color-red5;
}
