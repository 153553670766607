/**
* ==========================================================================
* _object.table.scss
* This is the data and comparison table styles
* ==========================================================================
*/

// table resets

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0;
  font: inherit;
  font-size: 100%;
}

// global table styles

// Standard static style (top border with grey color)
.ti-table {
  position: relative;
  border-collapse: collapse;
  border-top: 2px solid $color-grey7;
  width: 100%;
  
  .head.hidden {
    display: none;
  }
  .head.alternate {
    background: $color-teal1;
    overflow: hidden;
    button {
      padding: 0.5em 0.8em;
      margin: 0 2em 0 0;
      float: left;
    }
  }
  thead {
    background: $color-grey1;
  }
  th {
    border-bottom: 2px solid $color-grey2;
    text-align: left;
    vertical-align: middle;
    color: $color-grey8;
    padding: 1em .6em;
    font-size: .9em;
    font-weight: 500;
    background: $color-grey1;
    
    > span {
      cursor: pointer;
      display: block;
      position: relative;
    }
  }
  td {
    border-bottom: 1px solid $color-grey2;
    vertical-align: top;
    padding: .6em;
  }
  
  tbody {
    tr {
      &:hover {
        background: $color-grey1;
      }
    }
    tr.selected {
      background: $color-yellow1;
    }
    tr.open {
      td {
        border-bottom: none;
      }
    }
  }
  .search-parts {
    font-size: 1.125em;
    float: left;
    margin-top: .5em;
    position: relative;
    &::before {
      position: absolute;
      right: .2em;
      top: .25em;
      font-size: 1.4em;
      content: "\e8b6";
    }
    
    input {
      width: 14em;
      padding: .25em .3em;
      
      &[type="button"] {
        border: none;
        cursor: pointer;
        position: absolute;
        background: none;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        width: 2em;
      }
      
      &.btn-reset {
        display: none;
      }
    }
  }
  .search-parts.reset {
    &::before {
      content: "\e14c";
    }
    .btn-reset {
      display: block;
    }
    .btn-search {
      display: none;
    }
  }
  .auto-complete {
    background: white;
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 20em;
    overflow-y: auto;
    border: 1px solid silver;
    box-shadow: 3px 3px 3px rgba(200, 200, 200, 0.6);
    font-weight: normal;
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      background: none;
      margin: 0;
      padding: .3em;
      &:hover {
        background: $color-grey2;
      }
      span {
        background: $color-yellow2;
      }
    }
    li.active {
      background: $color-grey2;
    }
  }
  col.hilighted {
    background: $color-grey1;
    -webkit-animation: fadeOut 4s 2s forwards;
    animation: fadeOut 4s 2s forwards;
  }
  th.hilighted {
    background: $color-grey1;
    -webkit-animation: fadeOut 4s 2s forwards;
    animation: fadeOut 4s 2s forwards;
  }
  td.hilighted {
    background: $color-grey1;
  }
  
  //Standard sortable table style (RST/selection tool pattern w/ teal top border color)
  &.sortable {
    border-top: 2px solid $color-teal5;
    
    .head.alternate {
      background: $color-grey1;
    }

    th {
      position: relative;
      text-align: left;
      vertical-align: top;
      color: #333;
      padding: 1em .6em 1em 1.4em;
      font-size: .9em;
      font-weight: 500;

      &.sort-ascend,
      &.sort-descend {
        &::before {
          position: absolute;
          left: 0;
          color: $color-teal5;
          font-size: 25px;
          font-weight: 500;
          top: 4px;
          padding: 4px;
        }
      }
      &.sort-ascend {
        &::before {
          content: "\a71b";
        }
      }
      &.sort-descend {
        &::before {
          content: "\a71c";
        }
      }
    }
    
    tbody {
      tr {
        &:hover {
          background: $color-teal1;
        }
      }
      tr.selected {
        background: $color-yellow1;
      }
    }

    col.hilighted,
    th.hilighted,
    td.hilighted {
      background: $color-teal1;
    }
  }
}