/**
* ==========================================================================
* _component.crossreference.scss
* This is the cross reference page component styles
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Competitor cross reference search
* --------------------------------------------------------------------------
*/

.ti_cross-reference {
  padding: 2rem 0;

  .ti_disclaim {
    max-width: 50em;
    font-size: 0.75em;
  }
}

#XrefSearchActionForm input {
  color: $color-black;
}

/**
* --------------------------------------------------------------------------
* Op Amps parametric cross reference tool
* (http://www.ti.com/amplifier-circuit/op-amps/cross-reference.html)
* --------------------------------------------------------------------------
*/

.opAmpsCrossReferenceSearch .block_wrap {
  margin-bottom: 1rem;
}

.opAmpsCrossReferenceSearch .block_wrap h4 {
  padding-top: 1rem; // force space above "Competitor Part Number" title
}

.opAmpsCrossReferenceSearch .block_wrap > span {
  margin-right: 1rem; // force space between input sections
}

.opAmpsCrossReferenceSearch .block_wrap > span input[type='text'],
.opAmpsCrossReferenceSearch .block_wrap > span select {
  width: auto;
}
