/**
* ==========================================================================
* _object.modal.scss
* This is modal popup area
* ==========================================================================
*/

.ti_modal {
  background: $color-white;
  box-shadow: $global-boxShadow-4;
  margin-bottom: 2rem;
  position: absolute;
  z-index: 1000;
  overflow: auto;

  .ti_modal-title {
    padding: 1.5rem 1.5rem 0;
    margin: 0;

    img {
      max-width: 100%;
    }
  }

  .ti_modal-content {
    padding: 1.5rem;
    overflow: auto;

    :last-child {
      margin-bottom: 0;
    }
  }

  .ti_modal-close {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .ti_modal-close + .ti_modal-title,
  .ti_modal-close + .ti_modal-content {
    padding-right: 2.5em;
  }

  .ti_modal-cta {
    padding: 0 1.5rem 1.5rem;
  }

  &.mod-fixed {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .ti_modal-content {
      flex-grow: 1;
    }

    .ti_modal-cta {
      padding-top: 1rem;
    }
  }
}

.ti_modal-overlay {
  z-index: 4001;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);

  .ti_modal {
    position: relative;
    max-height: 95%;
    max-width: 95%;
    margin: 0 auto;
  }
}
