/**
* ==========================================================================
* _object.tabnav.scss
* This is the navigation tab object, including sub tab navigation
* ==========================================================================
*/

.tab, // legacy! name
.ti_tabs {
  position: relative;
  top: -1rem;
  display: inline-block;
  overflow: visible;
  width: calc(100% - 2rem);
  margin-bottom: 1rem;
  margin-left: 2rem;
  border-bottom: 1px solid $color-grey7;

  a {
    cursor: pointer;
    text-decoration: none;
    color: $color-grey8;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    display: flex;
    align-items: stretch;
    margin: 0;
    padding: 0 2rem;
    list-style: none;

    &::before,
    &::after {
      display: table;
      content: '';
    }

    &::after {
      clear: both;
    }
  }

  li {
    position: relative;
    float: left;
    margin: 0 1.4em 0 -0.7em;
    padding: 0.7em 0.1em 0.7em 0.7em;
    list-style: none;
    color: $color-grey3;
    background-image: url('https://www.ti.com/etc/designs/ti/images/ui/iconTab.svg');
    background-repeat: no-repeat;
    background-position: 0 0;

    &::after {
      position: absolute;
      top: 0;
      right: -24px;
      width: 25px;
      height: 100%;
      content: '';
      background-image: url('https://www.ti.com/etc/designs/ti/images/ui/iconTab.svg');
      background-repeat: no-repeat;
      background-position: 100% 0;
    }
  }

  .active, // legacy!
  .is-active {
    z-index: 1;
    background-position: 0 -120px;
    font-weight: $base-font-weight-bold;

    &::after {
      background-position: 100% -120px;
    }

    a {
      color: $color-white;
    }
  }
}

/* stylelint-disable */

.ti_subTabNav {
  position: relative;
  z-index: 10;
  top: calc(-2rem - 1px);
  clear: both;
  width: calc(100% - 2rem);
  height: 40px;
  margin: 0 0 3px 2rem;
  margin-left: 2rem;
  background-color: $color-grey7;
}

.ti_subTabNav ul {
  float: left;
  width: auto;
  margin: 0;
  list-style: none;
  font-size: 0.96em;
}

.ti_subTabNav li {
  position: relative;
  float: left;
  margin-bottom: 3px;
  padding: 0.7em 1em 0.8em;
  list-style: none;
  transition: border-bottom 0.2s;
  background-color: $color-grey7;
  background-image: none;
}

.ti_subTabNav li:hover,
.ti_subTabNav li:hover span {
  transition: background-color 0.4s;
  border-bottom: 3px solid $color-teal4;
  background-color: #707070; // sub tab highlight color is not in variables or palette
}

.ti_subTabNav li:hover span {
  border-bottom: 0;
}

.ti_subTabNav .is-active {
  transition: border-bottom 0.2s;
  border-bottom: 3px solid $color-red4;
  font-weight: $base-font-weight-bold;
}

.ti_subTabNav li:hover,
.ti_subTabNav .is-active {
  margin-bottom: 0;
}

.ti_subTabNav a {
  color: $color-white !important;
}

.ti_subTabNav a:hover {
  text-decoration: none;
}

.ti_subTabNav .sub-tab-sub-menu {
  width: 6em;
  margin: 0;
  // padding: 7px 1em;
  padding: 0.5em 1em 1em;
  height: 2.9em;

  .ti_icon {
    position: relative;
    top: 0.2em;
  }

  &:hover {
    transition: background-color 0.4s;
    background-color: #707070; // sub tab highlight color is not in variables or palette
  }

  > p {
    margin: 0;
    padding: 0 0 .7em 0;
    line-height: 1.8;
  }

  ul {
    position: relative;
    top: 0;

    &.show {
      opacity: 1;
      transition: opacity 0.4s;
    }
  }

  &.is-expanded svg {
    transform: rotate(180deg);
    transition: transform 0.15s ease-in-out;
  }
}

.sub-tab-anchor li {
  float: left;
  background-image: none;
}

.sub-tab-anchor::before {
  margin: 0 1em;
  content: ' | ';
}

.sub-tab-anchor:first-child::before {
  margin: 0.25em;
  content: '';
}

.sub-tab-sub-menu {
  position: relative;
  float: left;
  width: 12%;
  margin: 0 0.8em;
  padding: 0.2em 0.1em;
  cursor: pointer;
  text-align: right;
  white-space: nowrap;
  color: $color-white;
  font-size: 0.96em;
}

.sub-tab-sub-menu ul {
  position: absolute;
  top: 107%;
  left: -1.02em;
  overflow: hidden;
  width: 0 !important;
  height: 0;
  transition-delay: 0;
  transition-timing-function: ease;
  transition-duration: 0.1s;
  opacity: 0;
}

.sub-tab-sub-menu ul.show {
  width: auto !important;
  height: auto;
  transition-delay: 0;
  transition-timing-function: ease;
  transition-duration: 0.1s;
  opacity: 1;
}

.sub-tab-sub-menu ul li {
  width: 229px;
  margin: 0;
  text-align: left;
}

.sub-tab-sub-menu ul li:hover,
.sub-tab-sub-menu ul li:hover span {
  border-bottom: 0;
}

.sub-tab-sub-menu button {
  margin: auto;
  outline: 0;
}

.sub-tab-sub-menu p {
  display: block;
  float: left;
  width: 30%;
  margin: 0.5em 0 0 0.4em;
  text-align: left;
}

.sub-tab-sub-menu i.icon-wordreplace {
  float: left;
  margin: 0.15em 0 0;
  padding: 0.1em 0;
}

.sub-tab-sub-menu i.icon-wordreplace:after {
  margin: 0 0 -0.1em 0;
}

.sub-tab-sub-menu button .icon-wordreplace {
  font-size: 1em;
}

/* stylelint-enable */
