/**
* ==========================================================================
* _object.pagination.scss
* Styles for pagination component
* ==========================================================================
*/

.ti-pagination {
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1em 0;
  width: 100%;

  a {
    padding: 0.2em 0.75em;
    margin: 0 0 0 0.5em;

    &:hover {
      text-decoration: none;
    }
  }

  .active {
    color: $color-white;
    border: 0;
    background-color: $color-teal4;
    border-radius: $global-radius;
    margin: 0 0 0 0.75em;
    padding: 0.4em 0.75em;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
  }

  .disabled {
    fill: $color-grey3;
    color: $color-grey8;
    pointer-events: none;
    cursor: default;
  }

  .ti_icon {
    margin-bottom: 2px;

    &:hover {
      fill: $color-teal5;
    }
  }

  .ti-pagination-element {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 1rem 1rem;
    text-align: left;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    .ti-pagination-list {
      display: flex;
      list-style: none;
      align-items: center;
      margin-bottom: 0;
      justify-content: flex-start;
    }

    .ti-pagination__item {
      margin-bottom: 0;
    }
  }

  .ti-pagination-element--pagination {
    flex-grow: 1;
    justify-content: flex-end;
    justify-self: flex-end;
    margin-left: 0;
    text-align: right;

    .ti-pagination-list {
      justify-content: flex-end;
    }

    .ti_icon:first-child {
      margin-left: 0;
    }
  }

  .ti-pagination-element--results {

    &:nth-last-child(n + 3) {
      flex-basis: 100%;
      margin-bottom: 1rem;

      + .ti-pagination-element {
        margin-left: 0;
      }
    }
  }
}
