/**
* ==========================================================================
* _global.legacyoverrides.scss
* Legacy overrides
* This is intended as hack fixes to improve legacy support for pages that
* previously used header.css and should be in emergencies only, and
* documented specifically.
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* To sunset
* --------------------------------------------------------------------------
*/

/* flexbox */

.same-height, // legacy!
.u-flex-row,
.u-sameheight {
  display: flex;
  flex-direction: row;
}

.u-flex-column {
  display: flex;
  flex-direction: column;
}

.u-flex-fill {
  flex: 1 1 auto;
}

.u-flex-shrink {
  flex: 0 1 auto;
}

/**
* --------------------------------------------------------------------------
* Header adjustments
* --------------------------------------------------------------------------
*/

// Legacy fix for header - kill the spacer <br> tag if child of #tiHeader and use more consistent margin to space instead

#tiHeader > br {
  display: none;
}

/**
* --------------------------------------------------------------------------
* General fixes
* --------------------------------------------------------------------------
*/

// match improved page margins

#maincontainer,
#bottomcontent,
#pgmargin {
  margin-left: 2rem;
}

#pgmargin {
  margin-right: 2rem;
  padding-top: 1rem;

  h1 {
    padding-top: 1rem;

    &:first-child {
      padding-top: 0;
    }
  }
}

#bottomcontent {
  margin-top: 2rem;
}

.clear { // legacy! clear class
  clear: both;
}

// legacy table reset
.tblstandard {
  border-top: initial;

  th {
    background: $color-grey2;
    font-weight: 600;
  }

  li {
    list-style: none;
  }
}

/**
* --------------------------------------------------------------------------
* Legacy! chapter nav - should be updated to new chapternav where used
* --------------------------------------------------------------------------
*/

.ti-chapter-nav {
  margin-bottom: 20px;
  background-color: $color-grey1;
  position: relative;
  z-index: 10;

  @include mix-clearfix();

  ul {
    //display: none;
    position: relative;
    z-index: 5;

    li {
      display: inline-block;
      height: 32px;
      background: none;
      margin: 0 20px;
      padding: 0;
      position: relative;

      a {
        font-size: 1em;
        line-height: 32px;
        height: 32px;
        display: block;
        color: #115566;
        position: relative;

        &:visited {
          color: #333;
        }
      }

      &:after {
        content: "|";
        position: absolute;
        right: -24px;
        top: 5px;
        color: #cccccc;
      }

      &:last-child:after {
        content: "";
      }

      &.active {
        a {
          color: #CC0000;
          font-weight: bold;

          &:hover {
            cursor: default;
            text-decoration: none;
          }

          &:after {
             content: " ";
            border-bottom: 2px solid #CC0000;
            width: 100%;
            padding: 0 5px;
            position: absolute;
            left: -5px;
            bottom: -20px;
          }
        }
      }
    }

    display: block;
    list-style: none;
    margin: 0;
    padding: 20px 0;
    float: left;

    &.ti-nav-show {
      display: block;
    }
  }

  .ti-chapter-nav-left {
    float: left;
  }

  h1 {
    font-size: 1em;
    position: relative;
    top: 12px;
    margin-left: 20px;
  }

  .nav-flip {
    float: right;
    padding: 20px 20px 0 0;
  }

  .button {
    background: #fff;
  }

}

body.ti-nav-sticky {
  .ti-chapter-nav {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10002;
    border-top: 0;

    // @include box-shadow(0, 0, 24px, 7px, rgba(0, 0, 0, 0.15));
  }
}

#filter {
  padding: 20px 20px 0;
}

.sticky > .inner {
  margin-left: 22%;
}

.scroll-spy-section {
  padding-top: 50px;
}

/**
* --------------------------------------------------------------------------
* Tool folder fixes - should probably be in folderTool.css
* (ex. http://www.ti.com/tool/tina-ti)
* --------------------------------------------------------------------------
*/

.softwareTable {
  margin: 0;
}

// Legacy fix for tool folder language select - should be replaced with common language select

#toggleFoldersDropDown {

  li {
    list-style: none;
  }
}

// breadcrumb padding for improved page margins

td .breadcrumbs {
  padding-left: .5rem;
}

// datasheet search results list

#Datasheet {
  padding-left: 2rem;

  li {
    list-style: none;
  }
}

// Img Gallery Thumbs fix

#galleryLeft {
  display: flex;

  li {
    list-style: none;
  }

  #navigation li.selected {
    border-color: $color-grey5;
  }
}

/**
* --------------------------------------------------------------------------
* Product folder fixes - should probably be in folderProduct.css
* significant work to get this working correctly (tables etc)
* --------------------------------------------------------------------------
*/

.relProd {
  clear: both;
}

// layout fixes from header.css
.folder.product .pageContent {
  padding: 0 2rem;
}

.folder.product .pageContent1 {
  float: left;
  width: 70%;
  //margin-left: 2rem;
}

.folder.product .pageContent2 {
  float: right;
  width: 30%;
  padding: 0 0 0 3rem;
  // margin-right: 2rem;
}

.ti-productpath-ribbon {
  margin: 0 2rem;

  .breadcrumb {
    padding-left: 0;
    margin-bottom: 0;
  }

  div.pageTitle3 {
    top: 126px;
    right: 48px;
  }
}

.ti-productpath-ribbon.pageTitle .keyDocument li {
  list-style: none;
}

.ti-productpath-ribbon.pageTitle .ti-productpath-linktitle a,
.ti-productpath-ribbon.pageTitle .keyDocument ul li a {
  font-weight: 600;
}

.tab {
  margin: 0;
  top: 0;
}

// legacy alert message box - use new one and remove this

.folder.product .alert-message {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 7em;
  margin: 2rem 0;
  padding: 1rem;
  border: 1px solid $color-teal3;
  border-radius: 0.35rem;
  background-color: $color-teal1;
  position: relative;
}

// list dots

#relEnds.relEnd .list li {
  background: none;
}

// legacy shore more button

.showMore {

  .showContent {
    max-height: 200px;
    overflow: hidden;
  }

  .showButton {
    font-size: .9em;
    border-top: 1px solid #e0e0e0;
    text-align: center;
    height: 1px;
    margin: 1.5rem 0 0 0;

    span {
      position: relative;
      top: -0.8em;
      background: #fff;
      color: $color-teal5;
      padding: 0 .5em;
      border-radius: $global-radius;
      cursor: pointer;
      z-index: 1;
    }
  }

  &.showLess .showContent {
    max-height: none;
  }
}

// legacy chapter anchor nav

.anchor {
  border-bottom: 1px solid silver;
  padding: 1em 0 .7em 0;
}

.anchor ul {
  margin: 0;
  padding: 0 2em;
  padding: 0 2rem;
}

.anchor li {
  list-style: none;
  background: none;
  color: silver;
  float: left;
  margin: 0;
  padding: 0;
}

.anchor li:after {
  content: '|';
  padding: 0 .5em;
}

.anchor li:last-child:after {
  content: none;
}

.anchor li.active a {
  background: #555555;
  color: #ffffff;
  padding: 1px 10px;
}

// parametric table fix - should use new tables or improve this component

#parametrics .tblstandard {
  table-layout: initial;
  position: relative;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  font-size: 1em;
  margin: 0 0 1em 0;
}

#parametrics .tblstandard th, #parametrics .tblstandard td {
  background: #ffffff;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: top;
  padding: 0.5em;
}

#parametrics .tblstandard th.image, #parametrics .tblstandard td.image {
  vertical-align: middle;
  text-align: center;
}

#parametrics .tblstandard th {
  background: $color-grey2;
  text-align: left;
  vertical-align: middle;
  color: #555555;
}

#parametrics #paramsName.tblstandard th, #parametrics #paramsName.tblstandard td {
  border-right: none;
}

// ti designs section

.ti-designs-section {

  .designList li,
  li.pdf,
  li.download,
  .TIdevices li {
    list-style: none;
  }

  .TIdevices {
    margin-left: 1rem;
  }
}

// global table styles

.ti_table,
.tblstandard /* legacy! */ {
  margin-bottom: 2rem;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;

  &.mod-fullWidth {
    min-width: 100%;
  }

  th,
  td {
    padding: 0.5em;
    text-align: left;
    border: 1px solid $color-grey3;

    &.u-text-center {
      text-align: center;
    }
  }

  thead th {
    //vertical-align: middle;
  }

  tbody tr:not(.mod-subheader):not(.mod-cta):not(.mod-heading3) {
    // for js row hover fix

    .hover {
      background-color: $color-teal1;
    }
  }

  tbody tr:not(.mod-subheader):not(.mod-cta):not(.mod-heading3):hover {
    // fallback for non-js row hover

    th:not(.hover):not([rowspan]),
    td:not(.hover):not([rowspan]) {
      background-color: $color-teal1;
    }

    td[rowspan]:not(.hover):hover ~ td {
      background: none;
    }
  }

  .icon-wordreplace {
    display: inline;
  }

  a .icon-wordreplace {
    // force teal on check links
    color: $color-teal5 !important;
  }
}

// modifier for comparison table variant

.ti_table.mod-comparison {
  empty-cells: show;
  border-collapse: separate;

  th,
  td {
    border: 0;
    border-top: 1px solid $color-grey3;

    // &:empty {
    //   border: 0 !important;
    //   background-color: transparent !important;
    // }
  }

  thead th {
    border-top: none;
  }

  td {
    text-align: center;

    &.u-text-left {
      text-align: left;
    }

    &.u-text-right {
      text-align: right;
    }
  }

  p {
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 115px;
  }

  tbody th[rowspan] {
    vertical-align: middle;
  }

  // tbody td {
  //   vertical-align: middle;
  // }
}

// modifier for horizontal lined variant
// .ti_table.mod-horizonline {

// }

.ti_table-title {
  text-align: left;
}

// row headers

.ti_table-row.mod-heading3 th {
  color: $color-white;
  background-color: $color-teal6;
  font-weight: $base-font-weight-bold;

  a {
    color: $color-white;
  }
}

.ti_table-row.mod-heading2 th {
  color: $color-grey8;
  background-color: $color-grey4;
  font-weight: $base-font-weight-bold;
}

.ti_table-row.mod-heading1 th {
  color: $color-grey8;
  background-color: $color-grey2;
  font-weight: $base-font-weight-bold;
}

// row subheader

.ti_table-row.mod-subheader th {
  text-align: left;
  color: $color-grey8;
  border-top: 1px solid $color-teal5;
  background-color: $color-grey1;
  font-weight: $base-font-weight-bold;
}

// row subheader title text

.ti_table-subheader {
  margin: 0;
  padding: 0;
  font-size: 1em;
}

// cta row for comparison table

.ti_table-row.mod-cta {

  a {
    display: block;
    margin: 0.5em 0;
  }
}

.ti_table-row.mod-footnote td {
  padding-top: 1rem;
  border: none;
  font-size: 0.8em;
}

// row centered text

.ti_table-row.mod-centered {

  td,
  th {
    text-align: center;
  }
}

// row header column cells

.ti_table-headercolumn {
  min-width: 100px;
  font-weight: $base-font-weight-bold;
}

// sortable styles

.ti_table th.header {
  cursor: pointer;

  &:hover {
    background-color: $color-grey1;
  }
}

.ti_table th .sorting-icons {
  display: none;
}

.ti_table.js-table-sortable thead tr:last-child th .sorting-icons {
  display: inline-block;
}

.ti_table th.headerSortDown,
.ti_table th.headerSortUp {
  // background-color: $color-grey1;

  &:before {
    margin-right: 0.5em;
    // color: $color-teal5;
    font-weight: $base-font-weight-bold;
    line-height: 1.2em;
  }
}

// .ti_table .header:before {
//   margin-right: 0.5em;
//   content: '\e0c3';
//   //color: $color-grey6;
//   font-weight: $base-font-weight-bold;
//   line-height: 1.2em;
// }


// .ti_table th.headerSortUp:before {
//   content: '\e5d8';
// }

// .ti_table th.headerSortDown:before {
//   content: '\e5db';
// }

// heading level dependent active sort bg color

.ti_table tr.ti_table-row {

  &.mod-heading3 {

    th.headerSortDown,
    th.headerSortUp {
      background-color: $color-teal5;

      &:before {
        color: $color-white;
      }
    }
  }

  &.mod-heading2 {

    th.headerSortDown,
    th.headerSortUp {
      background-color: $color-grey3;

      &:before {
        color: $color-grey7;
      }
    }
  }

  &.mod-heading1 {

    th.headerSortDown,
    th.headerSortUp {
      background-color: $color-grey1;

      &:before {
        color: $color-grey8;
      }
    }
  }
}

.ti_table .ti_icon.arrow-up,
.ti_table .ti_icon.arrow-down {
  display: none;
}

.ti_table th.headerSortDown .ti_icon.sorting-arrows,
.ti_table th.headerSortDown .ti_icon.arrow-up {
  display: none;
}

.ti_table th.headerSortUp .ti_icon.sorting-arrows,
.ti_table th.headerSortDown .ti_icon.arrow-down {
  display: none;
}

.ti_table th.headerSortDown .ti_icon.arrow-down,
.ti_table th.headerSortUp .ti_icon.arrow-up {
  display: inline-block;
}

.ti_table .ti_icon.arrow-up.mod-size-s svg,
.ti_table .ti_icon.arrow-down.mod-size-s svg {
  padding: 2px;
}

// sticky header styles

.ti-sticky-container {
  position: fixed;
  z-index: 3;
  display: none;
}

.ti_table-text-right {
  float: right;
  text-align: right;
}

.ti_table-pagination {
  padding-bottom: 0.25em;
}

// Pagination

.ti_pagination {
  margin: 1em 0;
  text-align: right;
  cursor: default;

a {
  padding: .2em .75em;
  margin: 0 0 0 .75em;

  &:hover {
    text-decoration: none;
  }

  &.active {
    color: #fff;
    border: 0;
    background-color: #00bbcc;
    border-radius: $global-radius;
    margin: 0 0 0 .75em;
    padding: .4em .75em;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
  }

  &.disabled {
    fill: #ccc;
    color: #333;
    pointer-events: none;
    cursor: default;
  }

  &.ti_icon {
    margin-bottom: 2px;

    &:hover {
      fill: #007c8c;
    }
  }
}

div {

  &.SumoSelect {
    vertical-align: middle;

    & > .CaptionCont {

      & > span {
        padding-right: 1em;
      }
    }
  }
}

p {

  &.SelectBox {

    & > span {
        margin: 0;
    }
  }
}

span {

  &:nth-of-type(2) {
    margin: 0 1em 0 2.5em;
  }
}
}

.ti_icon-readerText {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/* Legacy filter button name */

.filter-buttons {
  display: inline-block;
  width: 100%;
  border-bottom: $global-line-dotted;
}

.filter-buttons button {
  padding-right: 2.3em;
  text-align: left;
  white-space: normal;
  color: $color-teal5;
}

.filter-buttons button:hover {
  cursor: default;
  color: $color-grey2;
  border: 1px solid $color-grey2;
  background-color: $color-grey1;
}

.filter-buttons button.close:before {
  float: right;
  margin: 0 -1.5em 0 0;
  padding: 0 0 0 0.75rem;
  content: '\2716'; // x multiplication: &#10006;
  color: $color-grey7;
  font-size: 1em;
}

.filter-buttons button.close:hover:before {
  color: $color-red5;
}

.filter-buttons button.more:before {
  float: right;
  content: '\203A'; // chevron
  font-size: 1em;
}

.filter-buttons span.button {
  color: $color-grey7;
  font-weight: $base-font-weight;
  cursor: default;

  &:hover {
    color: $color-grey7;
    border: 1px solid $color-grey4;
  }
}

.filter-buttons .button {
  white-space: normal !important;
}

.filter-buttons ul {
  margin-left: 0;
  list-style-type: none;

  @include mix-clearfix();
}

.filter-buttons ul > ul {
  margin-left: 1em;
}

.filter-buttons ul li {
  float: left;
  margin-left: 0;
  background: none;
}

.filter-buttons ul li ul li {
  padding-left: 1.4rem;
  background: transparent url('https://www.ti.com/assets/images/hierarchy-indicator.png') no-repeat scroll 0.5em -0.25em;
}
