/**
* ==========================================================================
* _object.accordions.scss
* This is the collapsible accordions object
* ==========================================================================
*/

/* stylelint-disable */

/* accordion font icon symbols config
 * legacy! - to be replaced with svg icon */

$iconexpanded: '\e5ce' !default; // chevron
$iconcollapsed: '\e5cf' !default; // chevron

/* collapsible accordion */

.collapsible {
  margin-bottom: 2rem;

  .collapsible-item {
    border: 1px solid transparent;
    border-width: 1px 0;

    .collapsible-item-trigger {
      clear: both;
      margin: 0;
      padding: 0.8em 0.8em;
      cursor: pointer;
      transition: background 500ms;
      background: none;

      &:hover {
        background: $color-grey1;
      }

      .ti_icon {
        margin-right: 0.5rem;
        border-radius: $global-radius;
        float: right;

        svg {
          transition: transform 0.15s ease-in-out;
        }
      }
    }

    .collapsible-item-content {
      display: none;
      margin: 0.8em;
    }

    h2,
    h3 {
      font-size: 1.2em;
    }

    h4,
    h5 {
      font-size: 1em;
    }
  }

  .collapsible-item.is-expanded {
    border: $global-line-dotted;
    border-width: 1px 0;

    + .is-expanded {
      border-top: none;
    }

    .collapsible-item-trigger {
      background: none;

      &:hover {
        background: none;
      }

      .ti_icon {
        //background-color: $color-red5;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.collapsible.mod-collapsible-bg { // collapsible modifier - background highlight

  .collapsible-item {

    .collapsible-item-trigger {
      background: $color-grey1;

      &:hover {
        background: $color-grey2;
      }
    }
  }

  .collapsible-item.is-expanded {

    .collapsible-item-trigger {
      background: transparent;
    }
  }
}

.collapsible.mod-collapsible-toolbox {
  // collapsible modifier - toolbox

  .collapsible-item {

    .collapsible-item-trigger {
      background: $color-grey1;

      &:hover {
        background: $color-grey2;
      }

      .ti_icon {
        float: right;
        margin-right: 0;
      }
    }

    ul.collapsible-item-content {
      margin: 0 0 -1px 0;
      padding: 0;

      li {
        padding: 0.6em 0.8em;
      }
    }

    .collapsible-item-content {
      margin: 0.8em;
      padding: 0;
    }
  }

  .collapsible-item.is-expanded {
    border-color: transparent;

    .collapsible-item-trigger {
      background: $color-grey1;

      &:hover {
        background: $color-grey2;
      }

      .ti_icon {
        background: transparent;
        fill: $color-grey7;
      }
    }
  }
}

.collapsible.mod-collapsible-text { // collapsible modifier - collapsible text

  .collapsible-item {

    .collapsible-item-trigger {
      position: relative;
      width: 100%;
      height: 4em;
      margin: -3.5em 0 1em 0;
      padding: 0;
      cursor: pointer;
      transition: background 500ms;
      border-bottom: 1px solid $color-grey2;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $color-white 100%);

      &::before {
        position: absolute;
        z-index: 3;
        bottom: -0.5em;
        left: 50%;
        display: block;
        width: auto;
        margin: 0 0 0 -1em;
        padding: 0 0 0 0.4em;
        // content: $iconcollapsed;
        color: $color-teal5;
        background: $color-white;
        //font-family: 'Material Icons', sans-serif;
      }

      span {
        position: relative;
        z-index: 2;
        top: 3.2em;
        left: 50%;
        display: inline-block;
        padding: 0 0.6em;
        color: $color-teal5;
        background-color: $color-white;
      }

      &:hover {
        border-color: $color-grey3;

        &::before,
        span {
          color: $color-red5;
        }
      }

      .collapsible-item-trigger-less {
        display: none;
      }

      .collapsible-item-trigger-more {
        display: inline-block;
      }
    }

    .collapsible-item-content {
      margin: 0;
    }

    p:last-child::after {
      display: inline-block;
      content: '..';
      transition: opacity 300ms;
    }
  }

  .collapsible-item.is-expanded {
    border-color: transparent;

    .collapsible-item-trigger {
      background: none;
      filter: initial;

      .collapsible-item-trigger-less {
        display: inline-block;
      }

      .collapsible-item-trigger-more {
        display: none;
      }

      // &::before {
      //   content: $iconexpanded;
      //   color: $color-teal5;
      //   background: $color-white;
      // }

      &:hover {
        background: none;
        filter: initial;

        // &::before {
        //   color: $color-red5;
        // }
      }
    }

    p:last-child::after {
      opacity: 0;
    }
  }

  &.mod-collapsible-textsmall { // collapsible text modifier - smaller area

    .collapsible-item {

      .collapsible-item-trigger {
        border-bottom: 0;

        span {
          left: 0;
          padding: 0 0 0 1.2em;
        }

        &::before {
          bottom: -0.4em;
          left: 0;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

/* expand all trigger */

.js-accordion-expand {
  float: right;
  padding: 0.5rem 0;
  cursor: pointer;
  color: $color-teal5;
}

.js-accordion-expand:hover {
  text-decoration: underline;
}

/* legacy more / less */

.tblstandard /* legacy! ? */ {

  .collapsible {

    .trigger {

      &::before {
        margin: 0.5em 0 0 0;
        content: 'less';
        color: $global-link-color;
        background: $color-white;
        font-family: $base-font-family;
        font-size: 0.9em;
      }
    }

    &.collapsed {

      .trigger {

        &::before {
          content: '... more';
        }
      }
    }

    .click {

      &::before {
        content: '...';
        color: $global-link-color;
        background: $color-white;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
}

/* stylelint-enable */
