/**
* ==========================================================================
* _component.header.scss
* This is the global TI.com header component
* ==========================================================================
*/

/* stylelint-disable */

/* ti.com header specific styles */

#tiHeader {
  // position: relative;
  // width: 100%;
  // background: $color-white;
  z-index: 2001; // lowest z-index for header (http://4com.itg.ti.com/ims/styleguide_fab/01-ui-style-foundations.html#08-z-index)
  //min-width: 1230px;
  min-width: $page-min-width;

  > div {
    @include mix-clearfix(); // clearfix divs
  }

  ul,
  li {
    list-style-type: none; // disable all list item dot styles
  }

  // ul > li {
  //   padding: 0;
  //   background: none;
  // }

  .top,
  .nav {

    li {
      float: left;
      margin: 0;
    }
  }

  .top {
    padding: 1rem 0;

    ul {
      padding: 0 $global-space-xl;
      // margin-left: 0;
    }
  }

  .nav {
    height: 42px;
    // position: relative;
    // z-index: 1
    background: $color-red5;

    ul {
      margin-left: -1rem;
      padding: 0 $global-space-xl;
      //margin-right: -1rem;
    }

    li {

      a {
        display: block;
        padding: 0 1rem;
        text-decoration: none;
        color: $color-white;
        font-weight: $base-font-weight-bold;
        line-height: 42px;

        &:hover {
          color: $color-white;
          font-weight: $base-font-weight-bold;
        }
      }

      &.active {

        a {
          color: $color-black;
          background: $color-white;
        }
      }

      &.thirdPartyID {

        a,
        a:hover {
          margin: 0 1em 0 0;
          padding: 0.2em 0.7em 0.3em 0.5em !important;
          background: url('https://www.ti.com/etc/designs/ti/images/icons/iconMore2.png') no-repeat right 0.7em;
          background-image: url('https://www.ti.com/etc/designs/ti/images/icons/iconMore2.svg');
          font-size: 1.6em;
        }
      }
    }
  }

  .column {
    float: left;
    padding: 0 1em 0 0;

    &.middle {
      padding-top: 2.1em;
    }

    &.border {
      padding-left: 1em;
      border-left: 1px dotted $color-grey3;
    }

    > ul {
      margin-left: 0;
    }
  }

  #top_logo {

    a {
      display: block;
      width: 280px;
      height: 36px;
      background: url('https://www.ti.com/etc/designs/ti/images/ui/ic-logo.png') no-repeat 0 0;
      background-image: url('https://www.ti.com/etc/designs/ti/images/ui/ic-logo.svg');
    }
  }

  #top_search {
    padding: 0 3rem;
    line-height: 1.4em;
  }

  #top_login {
    float: right;
    margin: 0.5rem 1rem 0 0;
  }

  #nav_history,
  #nav_cart,
  #nav_language,
  #nav_profile,
  #nav_search,
  #eg_nav_history {
    float: right;

    a:before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 0.2em;
      vertical-align: middle;
      background-size:18px;
      background-repeat: no-repeat;
      background-position: 0 -72px;
    }

    &.active {

      a:before {
         background-position: 0 -36px;
      }
    }

    .eg-selected:before {
      background-position: 0 -36px;
     }
  }

  #nav_history,
  #eg_nav_history {

    a:before {
      background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/historyClock.svg);
    }

    &.active {

      a:before {
        background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/historyClock.svg);
      }
    }
  }

  #nav_cart {

    a:before {
       background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/shoppingCart.svg);
    }

    &.active {

      a:before {
         background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/shoppingCart.svg);
      }
    }
  }

  #nav_language {

    a:before {
       background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/globe.svg);
    }

    &.active {

      a:before {
       background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/globe.svg);
      }
    }
  }

  #nav_profile {

    a:before {
      background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/myTI.svg);
    }

    &.active {

      a:before {
        background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/myTI.svg);
      }
    }
  }

  #nav_search {

    a:before {
      background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-actions/search.svg);
    }

    &.active {

      a:before {
        background-image: url(https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-actions/search.svg);
      }
    }
  }

  #nav_menu,
  #nav_search {
    display: none;
  }

  .columnGroup {
    display: flex;
    align-items: stretch;
  }

  .sub {
    position: relative;
    display: none;
    padding: 1rem $global-space-xl;
    border: 1px solid $color-grey3;
    border-top: 0;
    background: $color-lightGradient;
    font-size: 0.9em;

    &.active {
      display: block;
    }

    h3 {
      color: $color-red5;
      font-size: 1.1em;
      font-weight: $base-font-weight-bold;

      a {
        color: $color-red5;
      }
    }

    p {
      max-width: 200px;
    }

    a {
      color: $alt-link-color;

      &.btnC {
        color: #fff;
      }

      &.more { // legacy! name
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: 100% -35px;
        background-image: url('https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-objects/chevron-right.svg'); // background svg icon
        padding-right: 18px;
      }
    }

    li {
      margin-bottom: 0.5rem;
    }

    ul ul {
      margin-top: 0.5rem;
      margin-left: 1rem;
    }
  }

  #sub_history {
    height: auto !important;

    .ABtitle {
      padding-left: 0;
      color: $color-red5;
      font-size: 1.1em;
      font-weight: $base-font-weight-bold;

      span {
        margin: 0 0 0 0.5em;
        font-size: 0.7em;
        font-weight: $base-font-weight;

        a {
          color: $color-red5;
        }
      }
    }

    #activityBar-content-normal {
      display: block;
    }

    #activityBar-content-expanded {
      display: none;
    }

    &.subExpanded {

      #activityBar-content-normal {
        display: none;
      }

      #activityBar-content-expanded {
        display: block;
      }

      .columnGroup {
        height: auto;
      }
    }

    ul {
      float: left;
      margin: 0;
    }

    #ABproduct,
    #ABproduct-before {
      width: 50%;
    }

    #ABtechdocs,
    #ABtechdocs-before {
      width: 30%;
      padding-left: 1em;
      border-left: 1px dotted $color-grey3;
    }

    #ABsearch,
    #ABsearch-before {
      width: 20%;
      padding-left: 1em;
      border-left: 1px dotted $color-grey3;
    }

    .activityBar-promoLine {
      padding-top: 0.5em;
      text-align: center;
      border-top: 1px dotted $color-grey3;
      font-weight: $base-font-weight-bold;
    }

    .ABPDpin,
    .ABPDtrash {
      display: inline-block;
      width: 2em;
      height: 1.4em;

      &:before {
        margin: 0.2em 0 0 0.5em;
        color: $color-grey5;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .ABPDpin {
      &:before {
        // content: '\e601';
        // font-family: 'ti-icons', sans-serif;
      }
    }

    .ABPDpin.pinned {
      &:before {
        color: $global-link-color;
      }
    }

    .ABPDpin.disabled {
      cursor: auto;

      &:before {
        color: $color-grey2;
      }
    }

    .ABPDtrash {
      margin-right: 0.5em;

      &:before {
        content: '\e14c';
      }
    }

    .totalPinnedItems {
      padding-left: 0;
      font-size: 0.8em;
    }

    .ABPDseparator {
      height: 1px;
      border-top: 1px dotted $color-grey3;
    }
  }

  #sub_applications {

    .column {
      width: 16.5%;
    }
  }

  ul.cartItem {

    li {
      position: relative;
      margin-bottom: 0;
      padding-right: 5em;
      border-bottom: 1px dotted $color-grey3;
    }
  }

  #sub_cart {

    ul.cartItem {

      span {
        position: absolute;
        right: 0;
      }
    }
  }

  .count {
    position: relative;
    top: -0.2em;
    left: 0.2em;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    color: $color-white;
    border-radius: 1em;
    background: $color-red5;
    font-size: 0.7em;
    font-weight: $base-font-weight-bold;
  }

  [id*='_search'] form {
    position: relative;
    display: inline-block;
    border: 1px solid $color-grey4;
    border-radius: 0.2em;

    &:before {
      float: right;
      margin: 0.5em 0.2em;
      content: '\e8b6';
      font-size: 1.2em;
    }
  }

  .searchFilterLable {
    display: inline-block;
    padding: 0.5em;
    cursor: pointer;
    border-right: 1px solid $color-grey4;
    border-radius: 0.2em 0 0 0.2em;
    background: $color-grey1;

    &:before {
      float: right;
      margin: 0.2em 0 0 0.2em;
      content: '\e5cf';
    }
  }

  .searchInput {
    width: 30em;
    padding: 0.5em;
    border: 0 !important;
    background: none;

    &:focus {
      animation: none; // kill focus style on this input
    }
  }

  .searchButton {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 2em;
    height: 2.5em;
    cursor: pointer;
    border: 0;
    background: none;
  }

  #headersupportSearch {
    // padding: 0.6rem;
  }

  #headersupportSearchbtn {
    margin-left: -33px;
  }

  #headersupportSearchAlert {
    margin-left: 0;
    margin-top: 2px;
  }

  .searchFlyout {
    position: absolute;
    z-index: 3000; // highest z-index for header (http://4com.itg.ti.com/ims/styleguide_fab/01-ui-style-foundations.html#08-z-index)
    display: block;
    display: none;
    max-width: 35.3em;
    padding: 1em;
    border: 1px solid $color-grey4;
    background: $color-grey1;
    font-size: 0.9em;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,0.3);

    &.active {
      display: block;
    }

    h4 {
      font-size: 1em;
    }

    &.filtered h4 {
      display: none;
    }

    p {
      margin: 0 0 0.5em 0;
      padding: 0;
      text-align: left;
      border-bottom: 1px dotted $color-grey3;
    }

    a {
      color: $alt-link-color;
    }

    div {
      display: none;

      &.active {
        display: block;
      }
    }

    .searchFilter p {
      padding-left: 20px;
      background: url('https://www.ti.com/etc/designs/ti/images/ui/iconSearch.png') no-repeat -2px -402px;
      background-image: url('https://www.ti.com/etc/designs/ti/images/ui/iconSearch.svg');

      &.product {
        background-position: -2px -702px;
      }

      &.technicalDocument {
        background-position: -2px -1002px;
      }

      &.support {
        background-position: -2px -1302px;
      }

      &.crossReference {
        background-position: -2px -1602px;
      }

      &.application {
        background-position: -2px -1902px;
      }

      &.forum {
        background-position: -2px -2202px;
      }

      &.blog {
        background-position: -2px -2502px;
      }

      &.designNetwork {
        background-position: -2px -2802px;
      }

      &.developerWiki {
        background-position: -2px -3102px;
      }

      &.training {
        background-position: -2px -3402px;
      }

      &.video {
        background-position: -2px -3702px;
      }
    }

    .searchHistory {
      margin: 2em 0 0 0;
    }

    &.filtered {
      left: -1px;
    }

    &.focused {
      right: -1px;
      width: 35.3em;

      .searchFilter p {
        display: none;

        &.product,
        &.technicalDocument,
        &.support {
          display: block;
        }
      }
    }
  }

  &.simplified {
    border-bottom: 8px solid $color-red5;
  }
}

/* stylelint-enable */
