/**
* ==========================================================================
* _mixins.all.scss
* This file contains global mixins used in sass
* prefix with mix-
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Utility mixins
*
* These are used in some of the utility classes, but are mixins to maintain
* usablility in areas where they have to be applied to exsisting rules,
* and where adding or changing classnames in html is not possible.
* --------------------------------------------------------------------------
*/

// Mixin to drop clearfix into a selector.
// (http://www.cssmojo.com/the-very-latest-clearfix-reloaded/)
//
// .usage {
//   @include mix-clearfix();
// }
@mixin mix-clearfix() {

  &::after {
    content: '' !important;
    display: block !important;
    clear: both !important;
  }
}

// Mixin to quickly apply accessible hiding to elements
// (Hide only visually, but have it available for screen readers.)
// (http://snook.ca/archives/html_and_css/hiding-content-for-accessibility)
//
// .usage {
//   @include mix-hidden-visually();
// }
@mixin mix-hidden-visually() {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;

  &.is-active, &.is-focusable {
    // Extends the class with `.is-active` or `.is-focusable` state modifier
    // to allow the element to be focusable when navigated to via the keyboard:
    // (https://www.drupal.org/node/897638)
    clip: auto;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
  }
}

// Mixin to hide text (for background images styles).
//
// .usage {
//   @include mix-text-hide();
// }
@mixin mix-text-hide() {
  //changed a to arial to satisfy sonar
  //font: '0/0' a;
  //font: '0/0' sans-serif;
  font-family: sans-serif;
  font-size: 0;
  line-height: 0;
  //
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// Mixin for common css only checkmark
// used on :after pseudo-elements in checkbox and stepper patterns
//
// .usage {
//   @include mix-css-checkmark({color});
// }

@mixin mix-css-checkmark($color) {
  width: 1em;
  height: 0.6em;
  content: '\00a0';
  transform: rotate(-45deg);
  border-bottom: 2px solid $color;
  border-left: 2px solid $color;
}
