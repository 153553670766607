/**
* ==========================================================================
* _object.videoplayer.scss
* This is the video player object / aem component
*
* This component needs support - some styles unused and implmented in js
* ==========================================================================
*/

/* stylelint-disable */

.video {
  margin-bottom: 2rem;
}

#entryDesc {
  height: 50px;
  margin: 0.5em 0 1em;
}

.galleryThumbTitle {
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8em;
}

.hand img {
  border: 1px solid #c0c0c0;
}

.galleryRelatedBox {
  overflow: auto;
  height: 281px;
  padding: 0.5em;
  border: solid 1px #c0c0c0;
  background: #ffffff;
}

.tagDesc {
  overflow: auto;
  height: 40px;
  margin: 0.5em 0 1em;
}

.durDesc,
.viewDesc,
.postDesc {
  overflow: auto;
  margin: 0.5em 0 1em;
}

.videoContainer {
  border: 1px solid #c0c0c0;
}

.ti_videoS .bc-player-SyZQ91bel_default {
  width: auto;
  height: auto;
  min-height: 120px;
  padding-top: 35%;
  border: 1px solid #aaaaaa;
}

.ti_videoH {
  display: flex;
  height: auto;
  flex: 1 1 0;
}

.ti_videoH > .ti_player,
.ti_videoH > .ti_playlist {
  display: flex;
}

.ti_videoH .vjs-playlist {
  height: 350px;
  margin: 0;
  border: 1px solid #aaaaaa;
  border-left: none;
}

.ti_videoH .bc-player-BJMvlwjfl_default {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 350px;
  min-height: 120px;
  border: 1px solid #aaaaaa;
  border-right: none;
}

.ti_videoH .ti_playlist {
  display: flex;
  max-width: 40%;
}

.ti_videoH .ti_player {
  display: flex;
  flex: 1 1 75%;
}

.ti_videoV {
  height: auto;
}

.ti_videoV .vjs-playlist {
  min-width: 0;
  height: auto;
  max-height: 330px;
  margin: 0;
  border: 1px solid #aaaaaa;
  border-top: none;
}

.ti_videoV .bc-player-BJMvlwjfl_default {
  box-sizing: border-box;
  width: auto;
  height: auto;
  min-height: 120px;
  padding-top: 57%;
  border: 1px solid #aaaaaa;
  border-bottom: none;
}

.ti_playlist .vjs-playlist {
  background-color: #ffffff;
}

.ti_playlist .vjs-playlist .vjs-selected .vjs-playlist-thumbnail::before {
  display: none;
}

.ti_playlist .vjs-playlist .vjs-selected .vjs-playlist-thumbnail > img {
  border: 3px solid #00bbcc;
}

.ti_playlist .vjs-playlist .vjs-playlist-item {
  text-align: left;
  background-color: #ffffff;
  font-family: 'open sans', sans-serif;
}

.ti_playlist .vjs-playlist .vjs-playlist-item:focus .vjs-playlist-thumbnail:before,
.ti_playlist .vjs-playlist .vjs-playlist-item:hover .vjs-playlist-thumbnail:before {
  top: 20px;
  left: 44.5px;
  width: 32px;
  height: 28px;
  color: #ffffff;
  border-radius: 0;
  background-color: rgba(90, 90, 90, .75);
  font-size: 22px;
  line-height: 28px;
}

.ti_playlist .vjs-playlist .vjs-playlist-item .vjs-playlist-description {
  line-height: 16px;
}

/* fixes for the BrigthCove player v5.18.3 */
.video-js *:before {
  font-family: 'VideoJS', sans-serif;
}

button.vjs-button {
  &:hover, &:focus {
    background: transparent;
  }
}

/* stylelint-enable */
