/**
* ==========================================================================
* _object.endequipmentselectiontool.scss
* This is the end equipment selection tool component styles
* (ex. http://www.ti.com/power-management/applications.html)
* previously in `p-tides.css` - updated with new styles
*
* Last edited on Wed Nov 29 2017, by Kayin McLeod
* ==========================================================================
*/

.TIDes_list {
  width: 100%;
  height: 270px;
  border-left: 1px solid $color-grey3;
  border-right: 1px solid $color-grey3;
  border-bottom: 1px solid $color-grey3;
  background-color: $color-white;
  font-size: 0;
}

.TIDes_list table {
  font-size: 12px;
}

.TIDes_list table tr:nth-child(2n) {
  background-color: $color-grey1;
}

.TIDes_prod ul li {
  margin-bottom: 0;
  list-style-type: none;
  text-transform: capitalize;
}

.TIDes_prod ul li a {
  display: block;
  padding: 0 1rem;
  line-height: 4em;

  &:hover {
    background-color: $color-teal1;
  }
}

.TIDes_pdf {
  width: 80px;
}

.TIDes_col03 {
  width: 25%;
}

.TIDes_resultHeaders {
  margin-top: 1rem;
  padding: 0.5em;
  color: $color-grey8;
  border-top: 1px solid $color-grey3;
  border-right: 1px solid $color-grey3;
  border-left: 1px solid $color-grey3;
  background-color: $color-grey2;
  font-weight: $base-font-weight-bold;
}

.TIDes_designHeader {
  text-align: left;
  font-weight: $base-font-weight-bold;
}

.TIDes_downloadHeader {
  font-weight: $base-font-weight-bold;
}

.tides-select {
  height: auto;
  padding: 1rem;
  background-color: $color-grey1;
  //border: $global-line-solid;
  box-shadow: $global-boxShadow-1;
}

.tides-select #filters {
  @include mix-clearfix();
}

.tides-select br.clear {
  display: none;
}

.tides-select .styled_select {
  float: left;
  margin-right: 1rem;
}

.tides-seltool {
  // width: 625px;
  width: 95%;
  border: 1px solid $color-grey7;
}

.tides-select .reset-link {
  float: left;
  line-height: 30px;
}
