/**
* ==========================================================================
* _object.breadcrumb.scss
* This is the breadcrumb navigation object
* ==========================================================================
*/

.breadcrumbs, // legacy! name
.breadcrumb {
  padding: 0 2rem 0.5rem;
  color: $color-grey4;
  font-size: 0.9em;

  a {
    color: $color-grey4;

    // is this used? (kayin)
    &::after {
      display: inline-block;
      padding: 0 0.25em;
      vertical-align: middle;
      color: $color-grey4;
    }
  }
}
