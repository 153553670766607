/**
* ==========================================================================
* _object.backtotop.scss
* This is the `back to top` page button
* ==========================================================================
*/

.btn-backToTop,
.btn-backToTop:visited {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 50%;
  display: none;
  padding: 0.5em 0;
  cursor: pointer;
  text-align: center;
  opacity: 0.4;
  color: $color-white;
  background-color: $color-grey6;
  font-size: 1.8em;

  .ti_icon {
    display: block;
  }

  span {
    position: relative;
    bottom: 0;
    left: 0;
    display: block;
    padding: 0;
    font-size: 12px;
    font-weight: $base-font-weight-bold;
  }

  &:hover {
    opacity: 1;
    background-color: $color-red5;
    text-decoration: none;
  }
}
