/**
* ==========================================================================
* _element.formelements.scss
* This is styles for common form elements
* ==========================================================================
*/

// .ti_forms-select {
//   margin: 0;
//   padding: 0.2em;
//   transition: border 0.2s;
//   color: $color-grey7;
//   border: 1px solid $color-grey3;
//   border-radius: 0.2em;
//   outline: 0;
//   font-size: 1em;
// }

// .ti_forms-select:focus {
//   border-color: $color-grey7;
// }

.form-label {
  color: $color-grey7;
  font-weight: $base-font-weight-bold;
  font-size: 1em;
  padding-bottom: 0.5em;
  padding-top: 1em;
  display: block;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='date'],
input[type='month'],
input[type='number'],
input[type='search'],
select,
textarea {
  margin-bottom: 0;
  width: auto; //100% to auto due to some issues in tools
  height: 30px;
  padding: 0 0.5rem;
  border: 1px solid #cccccc;
  // border-radius: 0.2em;

  &[disabled] {
    background-color: $color-grey2;
    cursor: not-allowed;
  }
}

select {
  width: auto;
}

textarea {
  padding: 7px;
  width: 100%;
  min-height: 150px;
  resize: vertical;
}

select[multiple] {
  min-height: 150px;
}

input,
select,
textarea {
  color: $color-grey7;
  font-size: 1em;

  &:focus {
    animation: focus-highlight linear 1s infinite alternate;
    outline: none;
  }
}

optgroup {

  &::before {
    background-color: $color-grey2;
    font-family: $base-font-family;
    font-weight: $base-font-weight-bold;
    padding: 0 0.2em;
    width: 100%;
  }
}

option[disabled],
optgroup[disabled] option,
optgroup[disabled]::before {
  color: $color-grey5;
  font-weight: $base-font-weight;
}

/* form container modifer for small height input fields */
.ti-form.mod-small {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='date'],
  input[type='month'],
  input[type='number'],
  input[type='search'],
  select,
  textarea {
    height: 23px;
  }
}

// required and error text
.webform-form .required, // legacy! name .webform-form
.ti-form .required {
  color: $color-red5;
  font-weight: $base-font-weight-bold;
}

.error-check {
  position: relative;
  padding: 0.25em 0 0 24px;
  color: $color-red5;

  .ti_icon {
    position: absolute;
    top: 2px;
    left: 0;
  }
}

/**
* --------------------------------------------------------------------------
* Custom Styled elements
* --------------------------------------------------------------------------
*/

/**
 * Custom styled checkbox and radio inputs
 */

.ti-custom-checkbox,
.ti-custom-radio {
  position: absolute;
  outline: none;
  opacity: 0;
}

.ti-custom-checkbox-label,
.ti-custom-radio-label {
  position: relative;
  display: inline-block;
  padding-left: 2rem;
  line-height: 1.5em;
  margin-bottom: 0.75rem;
  margin-right: 1rem;
  cursor: pointer;
}

// common checkbox and radio position and style placeholder
%custom-checkbox-radio {
  position: absolute;
  top: 0;
  left: 0;
  content: '\00a0';
  background: #ffffff;
  display: block;
  width: 22px;
  height: 22px;
  transition: all $animation-timing-selectionControls $animation-easing-smooth;
}

// custom checkbox styles
.ti-custom-checkbox-label::before {

  @extend %custom-checkbox-radio;

  border-radius: 3px;
  border: 2px solid $color-grey3;
}

.ti-custom-checkbox:checked + .ti-custom-checkbox-label::before {
  background: $color-grey7;
  border: 2px solid $color-grey7;
}

.ti-custom-checkbox:checked + .ti-custom-checkbox-label::after {
  position: absolute;
  top: 4px;
  left: 4px;

  @include mix-css-checkmark($color-white);
}

.ti-custom-checkbox:focus + .ti-custom-checkbox-label::before {
  animation: focus-highlight linear 1s infinite alternate;
  outline: none;
}

// custom radio styles
.ti-custom-radio-label::before {

  @extend %custom-checkbox-radio;

  border-radius: 50%;
  border: 2px solid $color-grey3;
}

.ti-custom-radio:checked + .ti-custom-radio-label::before {
  background: $color-grey7;
  border: 2px solid $color-grey7;
}

.ti-custom-radio:checked + .ti-custom-radio-label::after {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  content: '\00a0';
  border-radius: 50%;
  background-color: $color-grey7;
  border: 3px solid $color-white;
}

.ti-custom-radio:focus + .ti-custom-radio-label::before {
  animation: focus-highlight linear 1s infinite alternate;
  outline: none;
}

// custom form highlight for check and radio
.ti-custom-checkbox[disabled] + .ti-custom-checkbox-label,
.ti-custom-radio[disabled] + .ti-custom-radio-label {
  cursor: not-allowed;
  opacity: 0.25;
}

/**
 * Custom Dynamic Form field for `input[type='text']`
 */
input.ti-dynamic-form {
  border: 0 !important;
  background: transparent !important;
}

/**
 * Custom Styled toggle buttons
 */

.toggle-buttons {
  display: flex;

  label {
    min-width: 60px;
    font-weight: $base-font-weight;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    transition: background-color $animation-timing-selectionControls linear;
    background-color: $color-grey1;
    border-style: solid;
    border-width: 1px 1px 1px 0;
    border-color: $color-grey3;
    color: $color-grey7;
    cursor: pointer;

    &:first-of-type {
      border-radius: $global-radius 0 0 $global-radius;
      border-left-width: 1px;
    }

    &:last-of-type {
      border-radius: 0 $global-radius $global-radius 0;
      border-right-width: 1px;
    }
  }

  input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    /* stylelint-disable max-nesting-depth */
    &:checked {

      + label {
        background-color: $color-grey7;
        border-color: $color-grey7;
        color: $color-white;

        &::after {
          content: '\00a0';
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 0;
          padding-top: 0;
          border-radius: 100%;
          background-color: rgba(236, 240, 241, 0.2);
          transform: translate(-50%, -50%);
        }
      }

      &:focus + label {
        position: relative;
        overflow: hidden;

        &::after {
          animation: focus-btn ease-in-out 2s infinite;
        }
      }
    }
    /* stylelint-enable */
  }
}

/* modifer to add space for icon or button (prevents text over icon) */
.ti_p-input.mod-iconSpace {
  padding-right: 2rem;
}

/* modifier to setup icon in input background */
.ti_p-input.mod-iconBg {
  background-size: 24px;
  background-repeat: no-repeat;
}

/* modifier to define icon to use (filter icon) */
.ti_p-input.mod-icon-filter {
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-actions/filter.svg');
  background-position: calc(100% - 3px) -70px;
}
