/**
* ==========================================================================
* _config.variables.scss
* This file contains project-wide variables that need to be made available
* to the entire codebase.
*
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* color variables
* --------------------------------------------------------------------------
*/

// Standardize color palette.

$color-white: #ffffff !default;
$color-grey1: #f9f9f9 !default;
$color-grey2: #e8e8e8 !default;
$color-grey3: #cccccc !default;
$color-grey4: #b9b9b9 !default;
$color-grey5: #aaaaaa !default;
$color-grey6: #999999 !default;
$color-grey7: #555555 !default;
$color-grey8: #333333 !default;
$color-black: #000000 !default;

$color-teal1: #edf7f8 !default;
$color-teal2: #d9f1f4 !default;
$color-teal3: #9ed6df !default;
$color-teal4: #00bbcc !default;
$color-teal5: #007c8c !default;
$color-teal6: #115566 !default;

$color-red1: #fbeeee !default;
$color-red2: #f4dfdf !default;
$color-red3: #efb9b9 !default;
$color-red4: #ee0000 !default;
$color-red5: #cc0000 !default;
$color-red6: #990000 !default;

$color-yellow1: #ffffe5 !default;
$color-yellow2: #ffffb3 !default;
$color-yellow3: #fbe458 !default;
$color-yellow4: #ff9933 !default;

$color-green: #44bb55 !default;

$color-lightGradient: linear-gradient(180deg, $color-white 0%, $color-grey1 100%) !default;

/**
* --------------------------------------------------------------------------
* global style variables
* --------------------------------------------------------------------------
*/

// Standardize common UI treatments.

$global-radius: 0.25rem !default;
$global-transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1) !default; // remove

$global-boxShadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.1) !default;
$global-boxShadow-2: 0 1px 8px 0 rgba(0, 0, 0, 0.09), 0 3px 4px 0 rgba(0, 0, 0, 0.11), 0 3px 3px -2px rgba(0, 0, 0, 0.09) !default;
$global-boxShadow-3: 0 3px 5px -1px rgba(0, 0, 0, 0.08), 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 18px 0 rgba(0, 0, 0, 0.08) !default;
$global-boxShadow-4: 0 7px 8px -4px rgba(0, 0, 0, 0.06), 0 12px 17px 2px rgba(0, 0, 0, 0.08), 0 5px 22px 4px rgba(0, 0, 0, 0.06) !default;

$global-line-dotted: 1px dotted $color-grey4 !default;
$global-line-light: 1px dotted $color-grey2 !default;
$global-line-solid: 1px solid $color-grey4 !default;

/**
* --------------------------------------------------------------------------
* font variables
* --------------------------------------------------------------------------
*/

// Base typographical styles and sizes.

$base-font-family: 'Open Sans', 'Segoe UI', Tahoma, sans-serif !default;
$base-font-family-roboto: 'Roboto', 'Franklin Gothic Medium', Tahoma, sans-serif !default;
$base-font-family-system: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default;

$base-font-family-cn: 'Open Sans', '华文细黑', 'STXihei', 'PingFang TC', '微软雅黑体', 'Microsoft YaHei New', '微软雅黑', 'Microsoft Yahei', '宋体', 'SimSun', 'Helvetica Neue', 'Helvetica', Arial, sans-serif !default;
$base-font-family-jp: 'Open Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'Osaka', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'Helvetica Neue', 'Helvetica', Arial, sans-serif !default;
$base-font-family-ko: 'Open Sans', 'Apple SD Gothic Neo', 'NanumBarunGothic', '맑은 고딕', 'Malgun Gothic', '굴림', 'Gulim', '돋움', 'Dotum', 'Helvetica Neue', 'Helvetica', Arial, sans-serif !default;

$base-font-weight-light: 300 !default;
$base-font-weight: 400 !default;
$base-font-weight-bold: 600 !default;

$root-font-size: 16px !default;
$base-font-size: 14px !default; // 14px

$base-line-height: 20px !default;

// standardize common font colors

$global-link-color: $color-teal5 !default;
$alt-link-color: $color-grey7 !default;
$light-link-color: $color-grey4 !default;

/**
* --------------------------------------------------------------------------
* layout variables
* --------------------------------------------------------------------------
*/

$global-space-xs: 0.25rem !default;
$global-space-s: 0.5rem !default;
$global-space-m: 1rem !default;
$global-space-l: 1.5rem !default;
$global-space-xl: 2rem !default;

$page-min-width: 1300px !default;

/**
* --------------------------------------------------------------------------
* animation variables
* --------------------------------------------------------------------------
*/

// Easings
$animation-easing-decelerate: cubic-bezier(0, 0, 0.2, 1) !default;
$animation-easing-accelerate: cubic-bezier(0.4, 0, 1, 1) !default;
$animation-easing-smooth: cubic-bezier(0.4, 0, 0.2, 1) !default; //ease-in-out

// Timings
$animation-timing-fadeIn: 150ms !default;
$animation-timing-fadeOut: 75ms !default;

$animation-timing-selectionControls: 100ms !default;

$animation-timing-enter: 250ms !default; //Enter into screen
$animation-timing-exit: 200ms !default;
