/**
* ==========================================================================
* _elemnet.lists.scss
* This is styles for common lists
* legacy!
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Updated global list styling
* --------------------------------------------------------------------------
*/

ul,
ol,
dl {
  list-style-position: inside;
}

li {
  margin-bottom: 0.25rem;
}

li > ul,
li > ol {
  margin-top: 0.25rem;
  margin-left: 1rem;
}

/**
* --------------------------------------------------------------------------
* Updated lined and lined icon list styling
* --------------------------------------------------------------------------
*/

/* stylelint-disable selector-no-qualifying-type */

ul.lined {
  margin-left: 0;
  list-style-type: none;
  border-top: $global-line-dotted;
}

ul.lined li {
  margin: 0;
  padding: 0.5em 0;
  border-bottom: $global-line-dotted;
  background: no-repeat 0 0.5em;
}

ul.lined.icons li {
  list-style: none;
}

ul.lined.icons a {
  line-height: 24px;
}

ul.lined.icons .ti_icon {
  float: left;
  padding-right: 0.5rem;
  margin-top: 1px;
}

ul.lined p {
  margin-bottom: 0;
}

/* stylelint-enable */
