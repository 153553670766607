/**
* --------------------------------------------------------------------------
* _element.filterbuttons.scss
* Heirarchy filter buttons
* --------------------------------------------------------------------------
*/

.ti-filter-buttons {

  a,
  span {
    white-space: normal;
  }

  a {
    padding-right: 2rem;
    font-weight: $base-font-weight;
    position: relative;

    &::after {
      position: absolute;
      top: 0.3em;
      right: 0.5rem;
      width: 1rem;
      height: 1rem;
      content: '\2715'; // x multiplication: &#10006;
      text-align: center;
      color: $color-grey7;
      font-size: 1em;
      cursor: pointer;
    }
  }

  a:hover {
    border-color: $color-grey3;
    color: $color-grey3;
    cursor: default;

    &::after {
      color: $color-red5;
    }
  }

  span {
    color: $color-grey7;
    font-weight: $base-font-weight;
    cursor: default;

    &:hover {
      border-color: $color-grey4;
    }
  }

  ul {
    list-style: none;

    @include mix-clearfix();
  }

  li a::before {
    position: relative;
    left: -1.5em;
    top: -0.5em;
    width: 0.5em;
    border-left: $global-line-solid;
    border-bottom: $global-line-solid;
    content: '\00a0';
    line-height: 1em;
  }
}
