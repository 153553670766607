/**
* ==========================================================================
* _element.label.scss
* This is the all caps label
* ==========================================================================
*/

.ti-label {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: $base-font-weight-light;

  .mod-button {
    color: $color-grey8;

    &:hover {
      color: $color-teal5;
    }
  }
}
