/**
* --------------------------------------------------------------------------
* _element.tag.scss
* Tag element styles
* --------------------------------------------------------------------------
*/

.ti-tag {
  font-size: 11px;
  text-transform: uppercase;
  border-color: #b9b9b9;
  font-weight: 300;
  color: #333;
  display: inline-block;
  padding: .3em .6em;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: $global-radius;
  margin: 0 .75rem 1rem 0;
}
.ti-tag.mod-button {
  background-color: transparent;
  cursor: pointer;
}
.ti-tag.mod-button:hover {
  color: $color-teal5;
  text-decoration: none;
}

