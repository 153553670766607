/**
* ==========================================================================
* _global.utilities.scss
* Global utility classes
* These are common helper styles to be used on any element
* use the prefix .u-
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Common utilities
* --------------------------------------------------------------------------
*/

/**
 * Position and display helper classes
 */

// Clear floats without structural markup
.clear, // legacy!
.u-clearfix {
  @include mix-clearfix();
}

// Force display block
.u-show {
  display: block !important;
}

// Hide visually and from screen readers
.hidden, // legacy!
.u-hidden {
  display: none !important;
}

// Hide only visually, but have it available for screen readers
.u-hidden-visually {
  @include mix-hidden-visually();
}

// Hide visually and from screen readers, but maintain layout
.u-invisible {
  visibility: hidden;
}

// Navigational element using unordered list with inline list items
.u-navlist {
  display: flex;
  margin: 0;
  padding-left: 0;
  list-style: none;

  li {
    margin-right: 1rem;
  }
}

// Remove default bullet styling and padding on unordered list
.no-style, // legacy!
.u-list-unstyled {
  padding-left: 0;
  list-style: none;

  // Adjust for links using icons inside
  &.mod-icon a {
    display: flex;

    .ti_icon {
      margin-right: 0.5rem;
    }
  }
}

// Left float
.flleft, // legacy!
.u-pull-left {
  float: left;
}

// left float
.flright, // legacy!
.u-pull-right {
  float: right;
}

// Center block with width using auto margins
.u-center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Use sticky position on element
.u-sticky {
    // stylelint-disable
    position: -webkit-sticky;
    position: sticky;
    // stylelint-enable
}

// Set cursor to pointer indicating link or interaction
.u-cursor-pointer {
  cursor: pointer;
}

/*
 * to make a link wrapping an object wrapped svg work
 */

.u-svgObjectLink {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/**
 * Text related helper utilities
 */

.center, // legacy!
.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.right, // legacy!
.u-text-right {
  text-align: right;
}

.u-text-justify {
  text-align: justify;
}

.u-text-vcenter {
  vertical-align: middle;
}

.nowrap, // legacy!
.u-text-nowrap {
  white-space: nowrap;
}

.u-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-text-breakall {
  word-break: break-all;
}

.u-text-lowercase {
  text-transform: lowercase;
}

.u-text-uppercase {
  text-transform: uppercase;
}

.u-text-capitalize {
  text-transform: capitalize;
}

.u-text-strikethrough {
  text-decoration: line-through;
}

.u-text-hide {
  @include mix-text-hide();
}

/**
 * Font size, style and spacing helper classes
 */

// .u-font-s {
//   font-size: $m-font-size-s;
// }

// .u-font-m {
//   font-size: $m-font-size-m;
// }

// .u-font-l {
//   font-size: $m-font-size-l;
// }

// .u-font-xl {
//   font-size: $m-font-size-x;
// }

.u-font-weight-light {
  font-weight: $base-font-weight-light;
}

.u-font-weight-normal {
  font-weight: $base-font-weight;
}

.u-font-weight-heavy {
  font-weight: $base-font-weight-bold;
}

/**
* --------------------------------------------------------------------------
* Flexbox utility classes
* --------------------------------------------------------------------------
*/

/*
 * Flex parent setup
 */

.u-flex {
  display: flex;

  /*
   * Establish main-axis
   */

  &.mod-row {
    flex-direction: row; // left to right; default
  }

  &.mod-column {
    flex-direction: column; // top to bottom
  }

  /*
   * Set flex items to wrap onto multiple lines
   */

  &.mod-wrap {
    flex-wrap: wrap;
  }

  /*
   * Defines the alignment along the main axis
   */

  &.mod-justify-start {
    justify-content: flex-start; // items are packed toward the start line; default
  }

  &.mod-justify-end {
    justify-content: flex-end; // items are packed toward to end line
  }

  &.mod-justify-center {
    justify-content: center; // items are centered along the line
  }

  &.mod-justify-spaceBetween {
    justify-content: space-between; // items are evenly distributed in the line
  }

  &.mod-justify-spaceAround {
    justify-content: space-around; // items are evenly distributed in the line with equal space around them
  }

  // Disabled due to false positive in SonarCube - not available in IE
  // &.mod-justify-spaceEven {
  //   justify-content: space-evenly; // items are distributed so that the spacing between any two items (and the space to the edges) is equal
  // }

  /*
   * Defines the alignment along the cross axis
   */

  &.mod-align-stretch {
    align-items: stretch; // stretch to fill the container (still respect min-width/max-width); default
  }

  &.mod-align-start {
    align-items: flex-start; // cross-start margin edge of the items is placed on the cross-start line; default
  }

  &.mod-align-end {
    align-items: flex-end; // cross-end margin edge of the items is placed on the cross-end line
  }

  &.mod-align-center {
    align-items: center; // items are centered in the cross-axis
  }

  /*
   * Align flex container content when there is extra space in the cross-axis
   * (note: has no effect when there is only one line of flex items)
   */

  &.mod-content-stretch {
    align-content: stretch; // lines stretch to take up the remaining space; default
  }

  &.mod-content-start {
    align-content: flex-start; // lines packed to the start of the container
  }

  &.mod-content-end {
    align-content: flex-end; // lines packed to the end of the container
  }

  &.mod-content-center {
    align-content: center; // lines packed to the center of the container
  }

  &.mod-content-spaceBetween {
    align-content: space-between; // lines evenly distributed
  }

  &.mod-content-spaceAround {
    align-content: space-around; // lines evenly distributed with equal space around each line
  }
}

/*
 * Flex children items
 */

.u-flex-item {
  /* non defaults */

  &.mod-item-grow {
    flex-grow: 1;
  }

  &.mod-item-noshrink {
    flex-shrink: 0;
  }

  /*
   * Item self align
   */

  &.mod-align-stretch {
    align-self: stretch; // stretch to fill the container (still respect min-width/max-width); default
  }

  &.mod-align-start {
    align-self: flex-start; // cross-start margin edge of the item is placed on the cross-start line; default
  }

  &.mod-align-end {
    align-self: flex-end; // cross-end margin edge of the item is placed on the cross-end line
  }

  &.mod-align-center {
    align-self: center; // item is centered on the cross-axis
  }
}

/**
* --------------------------------------------------------------------------
* Spacing utilities
* --------------------------------------------------------------------------
*/

/*
 * margin and padding helper loop
 * generates helper classes for margin and paddings using the format:
 * .u-margin{-side?}-{size} and .u-padding{-side?}-{size}
 * for example,
 * .u-margin-top-s would add 0.5rem margin to top.
 * .u-padding-vertical-m would add 1rem margin to both sides.
 */

$spacing-type-map: (
  'margin', 'padding'
) !default;

$spacing-sizes-map: (
  '0':  0,
  'xs': $global-space-xs, // 0.25rem // 4px
  's':  $global-space-s,  // 0.5rem  // 8px
  'm':  $global-space-m,  // 1rem    // 16px
  'l':  $global-space-l,  // 1.5rem  // 24px
  'xl': $global-space-xl  // 2rem    // 32px
) !default;

$spacing-directions-map: (
  'vertical': vertical,
  'horizontal': horizontal,
  'top': top,
  'bottom': bottom,
  'left': left,
  'right': right
) !default;

@each $spacingType in $spacing-type-map {

  @each $spacingSizeName, $spacingSize in $spacing-sizes-map {

    .u-#{$spacingType}-#{$spacingSizeName} {
      #{$spacingType}: $spacingSize;
    }

    @each $spacingDirectionName, $spacingDirectionRule in $spacing-directions-map {

      .u-#{$spacingType}-#{$spacingDirectionName}-#{$spacingSizeName} {

        @if $spacingDirectionName == vertical {
          #{$spacingType}-top: $spacingSize;
          #{$spacingType}-bottom: $spacingSize;
        } @else if $spacingDirectionName == horizontal {
          #{$spacingType}-left: $spacingSize;
          #{$spacingType}-right: $spacingSize;
        } @else {
          #{$spacingType}-#{$spacingDirectionRule}: $spacingSize;
        }
      }
    }
  }
}

/**
* --------------------------------------------------------------------------
* Image container aspect ratio scaling
* --------------------------------------------------------------------------
*/

.u-image.mod-aspect { // 16:9 container for images - because object-fit/object-position doesn't work in IE
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 100% 0 0 0; // default aspect ratio of 1:1
  overflow: hidden;
}

.u-image.mod-aspect.mod-rectangle {
  padding: 56.25% 0 0 0; // aspect ratio of: 16:9 = 56.25% | 2:1 = 50%
}

.u-image.mod-aspect > img { // image fits inside container maintaining aspect ratio
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.u-image.mod-aspect.mod-cover > img { // image fills container maintaining aspect ratio
  max-height: none;
  left: -200%;
  right: -200%;
  top: -200%;
  bottom: -200%;
}

.u-image.mod-zoom {
  &::after {
    content: '\00a0';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    opacity: 0.75;
    background-color: $color-grey7;
    background-image: url('https://www.ti.com/etc/designs/ti/images/icons/svg/ti_icons-actions/zoom-in.svg');
    background-repeat: no-repeat;
    background-position: 0 -192px;
    background-size: 48px;
  }

  &:hover::after {
    opacity: 1;
  }
}

/**
* --------------------------------------------------------------------------
* Common visual style utilities
* --------------------------------------------------------------------------
*/

/**
 * common border radius
 */

.u-border-rounded {
  border-radius: $global-radius;
}

.u-border-circle {
  border-radius: 50%;
}

/**
 * common boxshadows
 */

.u-boxShadow-1 {
  box-shadow: $global-boxShadow-1;
}

.u-boxShadow-2 {
  box-shadow: $global-boxShadow-2;
}

.u-boxShadow-3 {
  box-shadow: $global-boxShadow-3;
}

.u-boxShadow-4 {
  box-shadow: $global-boxShadow-4;
}

/**
 * common background colors,
 * includes declaration for dark or light text color for readability
 */

.u-bgColor-teal1 {
  color: $color-grey8;
  background-color: $color-teal1;
}

.u-bgColor-teal2 {
  color: $color-grey8;
  background-color: $color-teal2;
}

.u-bgColor-teal3 {
  color: $color-grey8;
  background-color: $color-teal3;
}

.u-bgColor-teal4 {
  color: $color-grey8;
  background-color: $color-teal4;
}

.u-bgColor-teal5 {
  color: $color-white;
  background-color: $color-teal5;
}

.u-bgColor-teal6 {
  color: $color-white;
  background-color: $color-teal6;
}

.u-bgColor-red1 {
  color: $color-grey8;
  background-color: $color-red1;
}

.u-bgColor-red2 {
  color: $color-grey8;
  background-color: $color-red2;
}

.u-bgColor-red3 {
  color: $color-grey8;
  background-color: $color-red3;
}

.u-bgColor-red4 {
  color: $color-white;
  background-color: $color-red4;
}

.u-bgColor-red5 {
  color: $color-white;
  background-color: $color-red5;
}

.u-bgColor-red6 {
  color: $color-white;
  background-color: $color-red6;
}

.u-bgColor-white {
  color: $color-grey8;
  background-color: $color-white;
}

.u-bgColor-grey1 {
  color: $color-grey8;
  background-color: $color-grey1;
}

.u-bgColor-grey2 {
  color: $color-grey8;
  background-color: $color-grey2;
}

.u-bgColor-grey3 {
  color: $color-grey8;
  background-color: $color-grey3;
}

.u-bgColor-grey4 {
  color: $color-grey8;
  background-color: $color-grey4;
}

.u-bgColor-grey5 {
  color: $color-grey8;
  background-color: $color-grey5;
}

.u-bgColor-grey6 {
  color: $color-grey8;
  background-color: $color-grey6;
}

.u-bgColor-grey7 {
  color: $color-white;
  background-color: $color-grey7;
}

.u-bgColor-grey8 {
  color: $color-white;
  background-color: $color-grey8;
}

.u-bgColor-black {
  color: $color-white;
  background-color: $color-black;
}

.u-bgColor-yellow1 {
  color: $color-grey8;
  background-color: $color-yellow1;
}

.u-bgColor-yellow2 {
  color: $color-grey8;
  background-color: $color-yellow2;
}

.u-bgColor-yellow3 {
  color: $color-grey8;
  background-color: $color-yellow3;
}

.u-bgColor-yellow4 {
  color: $color-grey8;
  background-color: $color-yellow4;
}

.u-bgColor-hoverhighlight {
  color: $color-grey8;
  background: $color-teal1;
}

.u-bgColor-lightGradient {
  color: $color-grey8;
  background: $color-lightGradient;
}

/**
 * common text (and fill for svg) colors
 */

.u-color-teal1 {
  color: $color-teal1;
  fill: $color-teal1;
}

.u-color-teal2 {
  color: $color-teal2;
  fill: $color-teal2;
}

.u-color-teal3 {
  color: $color-teal3;
  fill: $color-teal3;
}

.u-color-teal4 {
  color: $color-teal4;
  fill: $color-teal4;
}

.u-color-teal5 {
  color: $color-teal5;
  fill: $color-teal5;
}

.u-color-teal6 {
  color: $color-teal6;
  fill: $color-teal6;
}

.u-color-red1 {
  color: $color-red1;
  fill: $color-red1;
}

.u-color-red2 {
  color: $color-red2;
  fill: $color-red2;
}

.u-color-red3 {
  color: $color-red3;
  fill: $color-red3;
}

.u-color-red4 {
  color: $color-red4;
  fill: $color-red4;
}

.u-color-red5 {
  color: $color-red5;
  fill: $color-red5;
}

.u-color-red6 {
  color: $color-red6;
  fill: $color-red6;
}

.u-color-white {
  color: $color-white;
  fill: $color-white;
}

.u-color-grey1 {
  color: $color-grey1;
  fill: $color-grey1;
}

.u-color-grey2 {
  color: $color-grey2;
  fill: $color-grey2;
}

.u-color-grey3 {
  color: $color-grey3;
  fill: $color-grey3;
}

.u-color-grey4 {
  color: $color-grey4;
  fill: $color-grey4;
}

.u-color-grey5 {
  color: $color-grey5;
  fill: $color-grey5;
}

.u-color-grey6 {
  color: $color-grey6;
  fill: $color-grey6;
}

.u-color-grey7 {
  color: $color-grey7;
  fill: $color-grey7;
}

.u-color-grey8 {
  color: $color-grey8;
  fill: $color-grey8;
}

.u-color-black {
  color: $color-black;
  fill: $color-black;
}

.u-color-yellow1 {
  color: $color-yellow1;
  fill: $color-yellow1;
}

.u-color-yellow2 {
  color: $color-yellow2;
  fill: $color-yellow2;
}

.u-color-yellow3 {
  color: $color-yellow3;
  fill: $color-yellow3;
}

.u-color-yellow4 {
  color: $color-yellow4;
  fill: $color-yellow4;
}

.u-color-green {
  color: $color-green;
  fill: $color-green;
}
