/**
* ==========================================================================
* _component.footer.scss
* This is the global TI.com footer component
* ==========================================================================
*/

/* stylelint-disable */

footer {
  @include mix-clearfix();
}

#tiFooter {
  clear: both;
  margin-top: 2rem;
  padding: 1em 2rem;
  color: $color-grey4;
  border-top: 1px solid $color-grey2;
  background: $color-white;
  font-size: 0.9em;
  min-width: $page-min-width;
}

#tiFooter p {
  max-width: none;
}

#tiFooter a {
  color: $color-grey4;
}

#tiFooter a:hover {
  color: $color-red5;
}

#tiFooter .link {
  float: left;
  width: 80%;
}

/* legacy! killing icon here */

// #tiFooter .link a.mobileSite {
//   padding: 5px 0 0 14px;
//   background: url('https://www.ti.com/etc/designs/ti/images/icons/mobile.gif') no-repeat 0 0;
// }

#tiFooter .social {
  float: right;
}

#tiFooter .social > div {
  padding: 1rem 0 0 1rem !important;
}

#tiFooter .social ul {
  @include mix-clearfix();
}

#tiFooter .social li {
  float: left;
  margin: 0 0 0 1rem;
  padding: 0;
  list-style: none;
  background: 0;
}

#tiFooter .social li a {
  display: block;
  width: 16px;
  height: 16px;
  background: url('https://www.ti.com/etc/designs/ti/images/icons/icon_follow-us-facebook.png') no-repeat 0 0;
  background-size: 16px;
}

#tiFooter .social li#twitter a {
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/icon_follow-us-twitter.png');
}

#tiFooter .social li#google a {
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/icon_google.png');
}

#tiFooter .social li#linkedin a {
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/icon_linkedin.png');
}

#tiFooter .social li#instagram a {
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/icon_follow-us-instagram.png');
}

#tiFooter .social li#e2e a {
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/e2e_footer.png');
}

#tiFooter .social li#youtube a {
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/icon_follow-us-youtube.png');
}

#tiFooter .social li#weibo a {
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/sina_weibo_icon_cn_footer.png');
}

#tiFooter .social li#cne2e a {
  //old background-image: url('https://www.ti.com/etc/designs/ti/images/icons/deyisupport_icon_cn_footer.png');
  background-image: url('https://www.ti.com/etc/designs/ti/images/icons/e2e_footer.png');
}

#tiFooter .social li#wechat a {
  background-image: url("https://www.ti.com/content/dam/ticom/images/identities/third-party/u-w/wechat-logo.png");
}

/* stylelint-enable */
