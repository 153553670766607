/**
* ==========================================================================
* _object.powerquicksearch.scss
* This is the power quick search component
* (eg. http://www.ti.com/power-management/overview.html)
* ==========================================================================
*/

.pqsearchwidget {
  margin-bottom: 2rem;
  padding: 1em;
  background: $color-grey1 !important;
  box-shadow: $global-boxShadow-1;
}

.pqsearchwidget h2,
.pqsearchwidget h3 {
  margin: 0 0 5px;
  color: $color-black;
}

.pqsearchwidget h2 {
  font-size: 20px;
}

.pqsearchwidget td {
  padding: 0.5em;
}

.pqsearchwidget .verticalLine {
  height: 100px;
  border-left: $global-line-dotted;
}

.pqsearchwidget .help {
  float: right;
  margin-top: 3px;
  margin-right: 400px;
  cursor: pointer;
  background: url('https://www.ti.com/graphics/analog/icon_help.gif') no-repeat 0 0;
}

.pqsearchwidget .pqsLine {
  line-height: 1em;

  .btn1 {
    background-color: $color-white;
  }

  td {
    text-align: right;
  }
}

.row .cntboxS2 img {
  vertical-align: middle;
}

.err {
  color: $color-red5;
}

#dynamic .row {
  margin-right: 0;
  margin-left: 0;
}
