/**
* --------------------------------------------------------------------------
* _element.stepper.scss
* Stepper / number list element
*
* cleaned up (converted to scss) by Kayin: 1/19/2018
*
* emmet snippets:
* ol.ti-styledList>(li>strong.ti-styledList-title)*3
* dl.ti-styledList>((dt>strong.ti-styledList-title)+dd)*3
* span.ti-circle
* --------------------------------------------------------------------------
*/

/**
* --------------------------------------------------------------------------
* Common circle element style mixin
* --------------------------------------------------------------------------
*/

@mixin mix-style-numberCircle {
  box-sizing: border-box;
  width: 2em; // Size the circle
  height: 2em; // Size the circle
  border-radius: 50%; // Make it round
  line-height: 2em; // Vertically center with the text
  text-align: center; // Center the text in the circle
  color: $color-white; // Default font color
  background: $color-grey8; // Default background color
  font-weight: $base-font-weight-bold; // Default font weight
  font-family: $base-font-family; // original : 'Helvetica Neue', 'Arial', sans-serif;
}

/* single grey pixel gif data uri; compressed in size */

$uri-pixel-grey: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==' !default;

/**
* --------------------------------------------------------------------------
* style for circle numbered list
* --------------------------------------------------------------------------
*/

.ti-styledList {
  margin-left: 0; // Remove the default list left margin
  padding-left: 0; // Remove the default list left padding
  counter-reset: listcount; // Initiate a counter
  // font-size: 14px; // (Optional) font size for all interior content, adjusting this should also correctly adjust margins etc.

  > li,
  > dt {
    position: relative; // Create a positioning context
    margin: 0; // Clear margins
    padding: 0 0 1rem 3em; // Add some spacing around the content and give space for number
    list-style: none;

    // Disable the normal item numbering
    &::before {
      position: absolute; // Position and style the number circle
      top: 0;
      left: 0;
      content: counter(listcount); // Use the counter as content
      counter-increment: listcount; // Increment the counter by 1

      @include mix-style-numberCircle;
    }
  }

  > dd {
    margin: 0 0 2em 0; // push left margin to match title position
    padding: 0 0 0 3em; // Add some spacing around the content
  }

  .ti-styledList-title {
    line-height: 2em; // Vertically center default inline title with the number
  }

  h2.ti-styledList-title {
    line-height: 1.25em; // Vertically center h2 with the number; sad but needed without restructuring as flexbox or something
  }

  h3.ti-styledList-title {
    line-height: 1.43em; // Vertically center h3 with the number
  }

  h4.ti-styledList-title {
    line-height: 1.67em; // Vertically center h4 with the number
  }

  /* List-style-type modifier examples */

  &.mod-styledList-roman {

    > li,
    > dt {

      &::before {
        content: counter(listcount, upper-roman); // Use the counter as content, list-style-type upper-roman
        font-family: 'Times New Roman', 'Times', serif;
      }
    }
  }

  &.mod-styledList-alpha {

    > li,
    > dt {

      &::before {
        content: counter(listcount, lower-alpha); // Use the counter as content, list-style-type upper-roman
      }
    }
  }

  /* Untouched/unvisited state */

  > .is-untouched {
    color: $color-grey3;

    &::before {
      color: $color-white;
      background: $color-grey3;
    }
  }

  /* Checkmark state */

  > .is-checked {

    &::before {
      content: '\00a0'; // fill content with empty space to place checkmark into
      color: $color-white;
      background: $color-teal5;
    }

    &::after {
      // make checkmark from tilted empty box with borders
      position: absolute;
      top: 0.6em;
      left: 1.5em;

      @include mix-css-checkmark($color-white);
    }
  }

  /* modified style for horizontal stepper list */

  &.mod-styledList-stepHorizontal {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.mod-styledList-stepLined {
      background-image: url($uri-pixel-grey);
      background-repeat: repeat-x;
      background-position: 0 1em;
    }

    > li {
      display: flex;
      flex: 0 1 auto;
      align-items: flex-start;
      margin: 0 2em 0 -1em;
      padding: 0 1em 0 4em;
      background-color: $color-white;

      // this covers parent line background - needs to modify if not on a white background */

      &::before {
        left: 1em;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  /* modified style for vertical stepper list */

  &.mod-styledList-stepVertical {

    &.mod-styledList-stepLined {

      > li:not(:last-child) {
        background-image: url($uri-pixel-grey);
        background-repeat: repeat-y;
        background-position: 1em 0;
      }
    }

    > .is-checked::after {
      left: 0.5em; // adjustment for check position on vertical list
    }
  }
}

/* Spacing adjustment for sub lists inside number lists */

.ti-styledList dd .ti-styledList,
.ti-styledList li .ti-styledList {
  padding-top: 2em;
}

/**
* --------------------------------------------------------------------------
* Stand alone inline circle span
* --------------------------------------------------------------------------
**/

.ti-circle {
  position: relative;
  display: inline-block; // let it be inline
  margin: 0 1em; // give space on side of circle

  @include mix-style-numberCircle;

  &.mod-circle-first {
    margin-left: 0; // remove space on left if it's the very first element
  }

  /* modification for putting wordreplace icon inside circle - legacy!? */

  > .icon-wordreplace {
    padding: 0 1em;
  }

  > .icon-wordreplace::after {
    line-height: 1.4em;
  }

  /* Size modifier for single circle */

  &.mod-circle-big {
    font-size: 1.6em;
  }
}
