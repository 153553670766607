/**
* ==========================================================================
* _global.aemoverrides.scss
* AEM overrides
* This is intended as hack fixes and should be in emergencies only, and
* documented specifically.
* ==========================================================================
*/

/* stylelint-disable */

/* Temporary fix to hide mobile bar */

.ti_m-mobileSwitch.is-hidden {
  display: none;
}

/* Fixes (hacks) to hide empty divs when no content entered */

.overviewContentText:empty {
  display: none;
}

/* Fix for tech Article Quick Search tool on AEM page
   (http://www.ti.com/analog-circuit/tech-article.html) */

#content_2 {
  display: block;
  margin-bottom: $global-space-m;

  .pFamily {
    background: $color-grey1;
    border: none;
    border-radius: 0;
    box-shadow: $global-boxShadow-1;
    overflow: visible;
    white-space: nowrap;
    padding: 10px;
  }

  div.gridbox {
    padding-top: 1rem;
    border: 0;
  }

  div.objbox {
    border-left: $global-line-solid;
    border-right: $global-line-solid;
    border-bottom: $global-line-solid;
  }
}

/* AEM specific fixes ? */

.author-only {
  display: none;
}

.aem-AuthorLayer-Edit .author-only {
  display: block;
}

.doc-category-lit-margin {
  margin-bottom: 20px;
}

.richText.text h2 {
  margin-left: 0;
}

.richText.text ul {
  list-style-position: outside;
  margin-left: .25rem;
}

.richText.text ul li {
  list-style-type: disc;
}

.richText.text li {
  margin: 0 0 0 1em;
  padding-left: 0;

  background-image: none;
}

.richText.parbase {
  margin-bottom: 2rem;
}

/* kill shadowbox load until fully removed */

#sb-container {
  display: none;
}

/* Negative margin alignment on AEM enhanced column control */

.enhancedColumnControl > div > .row {
  margin-right: -1rem;
  margin-left: -1rem;
}

/* Override for AEM edit mode - Forces accordions open to allow for drag and drop content */

.aem-AuthorLayer-Edit,
.aem-AuthorLayer-Design {

  .collapsible-item-content {

    &.js-collapsible-content {
      display: block !important;
    }
  }
}

/* reference design product dropdown */

.pageNavContent li {
  margin: 0;
  padding: .4em 0;
  border-top: 1px dotted #e0e0e0;
  background: none;
}

/* webench width fix */

// div.webenchDesigner {
//   width: 325px;
//   height: 379px;
// }

/* product family applications */

.productFamilyApplications-section tr.mod-alt,
.productFamilyApplications-section td.mod-alt {
  background-color: #f9f9f9;
}

/* adjustment for image width on featured lit component */

.featuredLiterature {
  margin-bottom: 2rem;

  .ti_box {
    height: auto;
  }
}

.featuredLiterature .ti_box-image {
  width: 33.3%;
}

.featuredLiterature .ti_box-literature {
  width: 66.6%;
}

/* Link list spacing fix */

.linkList .ti_box {
    padding-top: 1rem;
}

/* same height flexbox override fixes */

.u-sameheight .overview.parbase,
.u-sameheight .featuredProduct,
.u-sameheight .featuredTool,
.u-sameheight .featuredContent,
.u-sameheight .featuredLiterature,
.u-sameheight .storePromo,
.u-sameheight .featuredReferenceDesign{
  flex: 1 1 auto;
  height: 100%;
}

/* AEM FIX - kill the spacer <br> tag immediately after #tiHeader and use more consistent margin to space instead */

#tiHeader + br {
  display: none;
}

#tiHeader {
  margin-bottom: 1rem;
}

// for (aem) pages with no left side navigation

.content > .tabbedNavigation > nav.ti_tabs {
  margin-left: 0;
}

// styles for store promo component

.ti_box-content .ti-price {
  margin-bottom: 1.2em;
  font-size: .85em;
}

.ti_box-content .ti-price > div {
  margin-bottom: .18em;
}

.ti_box-content .ti-price .ti-listPrice {
  color: #cc0000;
  font-size: 1.24em;
  font-weight: 600;
}

.ti_box-content .ti-price .ti-listPrice .ti-currency {
  font-size: .80em;
}

.ti_box-content .u-text-strike {
  text-decoration: line-through;
}

/* Product Family Applications */

ul.ti_key-products {
  margin: 0;
}

ul.ti_key-products > li {
  padding: 0;

  background: none;
}

/**
* --------------------------------------------------------------------------
* Language specific adjustments
* --------------------------------------------------------------------------
*/

/* German specific styles */
.de-de {
  .button {
    white-space: normal;
  }

 .featuredTool,
 .storePromo,
 .featuredReferenceDesign {
    .ti_box-cta-button + .ti_box-cta-button {
      margin-left: 1.5em;
    }
  }

  h1, h2, h3, h4, h5, h6,
  p, li {
    overflow-wrap: break-word;
  }

  .ti_box-title.mod-large {
    font-size: 1.4em;
  }

  .ti_apps-nav #flyout_menu a#fl_resetLink {
    top: 1.8em;
    font-size: .9em;
  }

  .ti_apps-nav #flyout_menu .result-count {
    margin-bottom: 2em;
  }
}

/* stylelint-enable */
