/**
* ==========================================================================
* _object.overviewheader.scss
* This is the overview header object
* ==========================================================================
*/

/* new style */

.overviewHeader {
  position: relative;
  width: 100%;
  min-height: 190px;
  margin-top: 0;
  margin-bottom: 2rem;
  padding: 0 1.4rem;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: cover;
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/generic.jpg'); // default bg image

  > div {
    position: absolute;
    bottom: 2rem;
    width: 65%;
  }
}

.overviewHeader-heading {
  max-width: 100%;
  margin: 0;
  padding: 0 0 1rem 0;
  font-size: 2.2em;
  font-weight: $base-font-weight;

  .emphasis {
    color: $color-red4;
    font-weight: $base-font-weight-bold;
  }

  p {
    margin-bottom: 0; // clear errant p tag margins
  }
}

.overviewHeader-subHeading {
  max-width: 100%;
  margin: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 1.3em;
  font-weight: $base-font-weight;
}

.overviewContentText {
  margin: 0 1.4rem 2.8rem 1.4rem;
  font-size: 1.2em;
  line-height: 1.66;

  p {
    margin: 0 0 0 1rem;
  }

  p:last-child {
    margin: 0;
  }
}

/* Background styles modifiers */

.overviewHeader.mod-amplifiers {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/amplifiers.jpg');
}

.overviewHeader.mod-audio {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/audio.jpg');
}

.overviewHeader.mod-automotive {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/automotive.jpg');
}

.overviewHeader.mod-dataConverters {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/data-converters.jpg');
}

.overviewHeader.mod-dlp {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/dlp.jpg');
}

.overviewHeader.mod-industrialStrength {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/industrial.jpg');
}

.overviewHeader.mod-powerManagement {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/power-management.jpg');
}

.overviewHeader.mod-powerLogo {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/powertodoanything.jpg');
}

.overviewHeader.mod-sensingTech {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/sensors.jpg');
}

.overviewHeader.mod-clockAndTiming {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/clock-and-timing.jpg');
}

.overviewHeader.mod-interface {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/interface.jpg');
}

.overviewHeader.mod-isolation {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/isolation.jpg');
}

.overviewHeader.mod-logic {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/logic.jpg');
}

.overviewHeader.mod-motorDrives {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/motor-drives.jpg');
}

.overviewHeader.mod-sitara {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/sitara.jpg');
}

.overviewHeader.mod-processors {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/processors.jpg');
}

.overviewHeader.mod-microwave {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/rf-microwave.jpg');
}

.overviewHeader.mod-currentSensors {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/currentsensors.jpg');
}

.overviewHeader.mod-humiditySensors {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/humidity-sensors.jpg');
}

.overviewHeader.mod-magneticSensors {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/magnetic-sensors.jpg');
}

.overviewHeader.mod-mmwaveSensors {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/mmwave-sensors.jpg');
}

.overviewHeader.mod-specialtySensors {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/specialty-sensors.jpg');
}

.overviewHeader.mod-temperatureSensors {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/temperaturesensors.jpg');
}

.overviewHeader.mod-simpleLink {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/simplelink.jpg');
}

.overviewHeader.mod-switchesMultiplexers {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/switches-multiplexers.jpg');
}

.overviewHeader.mod-communicationsEquipment {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/communications-equipment.jpg');
}

.overviewHeader.mod-enterpriseSystems {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/enterprise-systems.jpg');
}

.overviewHeader.mod-appliances {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/appliances.jpg');
}

.overviewHeader.mod-buildingAutomation {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/building-automation.jpg');
}

.overviewHeader.mod-factoryAutomation {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/factory-automation.jpg');
}

.overviewHeader.mod-gridInfrastructure {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/grid-infrastructure.jpg');
}

.overviewHeader.mod-personalElectronics {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/personal-electronics.jpg');
}

.overviewHeader.mod-iot {
  background-image: url('https://www.ti.com/etc/designs/ti/images/overview-headers/internet-of-things.jpg');
}
