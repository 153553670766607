/**
* ==========================================================================
* _object.dlpdynamictable.scss
* DLP dynamic table tool
*
* Originally found in dlpDynamicTable.css
* (http://www.ti.com/dlp-chip/advanced-light-control/optics-electronics/solution-search.html)
* Needs review
* ==========================================================================
*/

/* stylelint-disable */

/* CSS Document - DLP for dynamic table */

.master_table {
  display: inline-block;
  width: 1008px;
  border: 1px solid #d9d9d9;
  border-top: none;
  background: gradient(top, #fffdfd, #f0f0f0);
}

.master_p {
  padding: 5px;
  color: #000000;
  border: none;
  font-size: 12px;
  font-weight: $base-font-weight-bold;
}

.sort {
  text-align: left;
  background: #ffffff;
}

.highlightbg {
  background: #ffffb3;
}

#match {
  margin-right: 4px;
  margin-left: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: #525252;
  background: #ffffb3;
  font-size: 12px;
  font-weight: $base-font-weight-bold;
}

// table-title {
//   font-family: Arial, sans-serif;
//   font-size: 1.5em;
// }

// .btn1,
// .btn3,
// .btnGry,
// .btndisabled {
//   overflow: visible;
//   padding: 1px 5px;
//   cursor: pointer;
//   color: white;
//   border: 1px solid #730000;
//   background: #d12424 url('https://www.ti.com/graphics/shared/css/btn-red-bg.gif') top left repeat;
//   font-size: 1em;
//   font-weight: $base-font-weight-bold;
// }

.group1,
.group3,
.group4 {
  float: left;
  width: 140px;
  margin-right: 5px;
  border-right: 1px solid #d7d7d7;
}

.group2 {
  float: left;
  width: 160px;
  margin-right: 5px;
  border-right: 1px solid #d7d7d7;
}

.group5 {
  float: left;
  width: 200px;
  margin-right: 5px;
  border-right: 1px solid #d7d7d7;
}

.group6 {
  float: left;
  width: 160px;
  margin-right: 5px;
}

.cnt.less {
  position: relative;
  overflow: hidden;
  height: 240px;
}

.gobtn {
  width: 19px;
  height: 20px;
  margin: 0 0 0 2px;
  padding: 0;
  cursor: pointer;
  vertical-align: middle;
  border: none;
  background: url('https://www.ti.com/lsds/media/images/mcu/submit-btn.png') no-repeat;
}

.resetbutton {
  display: inline-block;
  padding: 4px 8px;
  white-space: nowrap;
  color: #ffffff;
  border: 1px solid #c0c0c0;
  border-color: #5e5e5e;
  border-radius: $global-radius;
  background: #666666;
  background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #666666), color-stop(1, #111111) );
  background: -moz-linear-gradient( center top, #666666 5%, #111111 100% );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#666666', endColorstr='#111111');
  font-size: 11px;
  font-weight: $base-font-weight-bold;
}

.resetbutton:hover {
  text-decoration: none;
  color: #ffffff;
  background: #111111;
  background: -webkit-gradient( linear, left top, left bottom, color-stop(0.05, #111111), color-stop(1, #666666) );
  background: -moz-linear-gradient( center top, #111111 5%, #666666 100% );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#111111', endColorstr='#666666');
}

.resetbutton span.reset {
  display: inline-block;
  padding: 1px 0 0 19px;
  background: url('https://www.ti.com/lsds/media/images/mcu/reset.png') no-repeat 0;
}

.disable_selection {
  color: #aaa9a9;
}

/* --start-- Fix AEM conflicts*/

#designSearchParams td.description {
  padding-bottom: 3em;
}

#designSearchParams table.master_table {
  margin: 0;
  padding: 0;
}

#designSearchParams table td {
  padding: 1px;
}

#designSearchParams .showMore .showButton span,
#designSearchParams .cnt.less ~ .showMore a,
#designSearchParams .showMore ~ .showLess a {
  position: static;
  padding: 0;
}

#designSearchParams .showMore .showButton,
#designSearchParams.cnt.less ~ .showMore,
#designSearchParams .showMore ~ .showLess {
  height: auto;
  margin: 0;
  text-align: left;
  font-size: inherit;
}

#designSearchParams .row img {
  vertical-align: text-bottom;
}

#designSearchParams table#tblSolution {
  max-width: 1008px;
}

/* --end--- Fix AEM conflicts */

/* stylelint-enable */
