/**
* ==========================================================================
* _elements.loadingspinner.scss
* This is the loading spinner animated icon
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Common loading element style mixin and keyframes
* --------------------------------------------------------------------------
*/

@mixin mix-loader {
  padding-bottom: 1rem;
}

@mixin mix-loader-spinner {
  animation: clockwise 0.75s linear infinite;
  border: 0.25rem solid;
  border-color: $color-teal5 $color-teal5 $color-teal5 rgba($color-white, 0);
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  margin: 0 auto;
}

@mixin mix-loader-mod-fullpage {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba($color-white, 0.8);
}

@mixin mix-loader-mod-fullpage-spinner {
  position: relative;
  top: 40%;
}

@keyframes clockwise {
  100% {
    transform: rotate(360deg) translateZ(0);
  }
}

@keyframes counter-clockwise {
  100% {
    transform: rotate(-360deg) translateZ(0);
  }
}

/**
*  html:
*  <div class="ti-loader {mod-fullpage}">
*    <div class="ti-loader-spinner"></div>
*  </div>
*
*  use:
*  .mod-fullpage is optional to render over entire page, except nav bar.
*  when displaying fullpage modifier, you might want to
*  `classList.toggle('is-scrollLocked')` on `<body>` tag.
*/

.ti-loader {
  @include mix-loader;

  .ti-loader-spinner {
    @include mix-loader-spinner;
  }

  &.mod-fullpage {
    @include mix-loader-mod-fullpage;

    .ti-loader-spinner {
      @include mix-loader-mod-fullpage-spinner;
    }
  }

  &.is-hidden {
    display: none;
  }
}

// legacy version
.spinner-icon,
.spinner {
  @include mix-loader-spinner;
}

.spinner-target {
  position: relative;

  .spinner-container {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6) none repeat scroll 0 0;
  }

  .spinner {
    margin: 300px auto;
  }
}
