/**
* ==========================================================================
* _component.coveo.scss
* This is coveo search bar styles
* ==========================================================================
*/

/* stylelint-disable */

/*extracted from CoveoFullSearch.css*/
.CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-content,
.CoveoSearchInterface.coveo-small-facets .coveo-dropdown-content,
.CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-content {
  padding: 0;
}

.CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header,
.CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header,
.CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header {
  font-size: 12px;
  display: inline-block;
  padding: 0 7px;
  height: 22px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.09px;
  vertical-align: middle;
  white-space: normal;
  color: #1d4f76;
  cursor: pointer;
  text-transform: uppercase;
}

.CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header *,
.CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header *,
.CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header * {
  display: inline-block;
  margin: 0;
}

.CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header p,
.CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header p,
.CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header p {
  line-height: 16px;
}

.CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header .coveo-more-tabs,
.CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header .coveo-more-tabs,
.CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header .coveo-more-tabs {
  margin-left: 10px;
}

.CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header.coveo-hidden,
.CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header.coveo-hidden,
.CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header.coveo-hidden {
  display: none;
}

.CoveoSearchInterface.coveo-small-tabs .coveo-tab-section > a:last-of-type,
.CoveoSearchInterface.coveo-small-facets .coveo-tab-section > a:last-of-type,
.CoveoSearchInterface.coveo-small-recommendation .coveo-tab-section > a:last-of-type {
  margin-right: 20px;
}

.CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header-wrapper,
.CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header-wrapper,
.CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  // display: -webkit-inline-box;
  // display: -moz-inline-box;
  // display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  box-lines: single;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
}
.CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header-wrapper a,
.CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header-wrapper a,
.CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header-wrapper a {
  margin-right: 10px;
}

.coveo-media-max-width-480 .CoveoSearchInterface .coveo-tab-section {
  background-color: #f7f8f9;
}

.coveo-media-max-width-480 .CoveoSearchInterface .CoveoSearchbox {
  max-width: 800px;
  margin-right: 50px;
}

.coveo-media-max-width-480 .CoveoSearchInterface .CoveoSettings {
  margin-left: 0;
}

.coveo-media-max-width-480 .CoveoSearchInterface .CoveoSettings + .CoveoSearchbox {
  margin-right: 50px;
}

@media (max-width: 480px) {
  .CoveoSearchInterface .coveo-tab-section {
    background-color: #f7f8f9;
  }

  .CoveoSearchInterface .CoveoSearchbox {
    max-width: 800px;
    margin-right: 50px;
  }

  .CoveoSearchInterface .CoveoSettings {
    margin-left: 0;
  }

  .CoveoSearchInterface .CoveoSettings + .CoveoSearchbox {
    margin-right: 50px;
  }
}

.CoveoSearchInterface {
  min-width: 300px;
}

.CoveoSearchInterface > * {
  display: none;
  visibility: hidden;
}

.CoveoSearchInterface.coveo-waiting-for-query .coveo-search-section {
  display: block;
  visibility: visible;
  margin-top: 20%;
}

.CoveoSearchInterface.coveo-waiting-for-query .coveo-search-section .CoveoSettings {
  display: none;
}

.CoveoSearchInterface .coveo-search-section {
  max-width: 800px;
  margin: 54px auto 47px;
  padding: 0 10px;
}

.CoveoSearchInterface .coveo-search-section .coveo-media-max-width-480 {
  padding: 20px 10px;
  margin: 0 auto;
  width: 100%;
  max-width: none;
}

@media (max-width: 480px) {
  .CoveoSearchInterface .coveo-search-section {
    padding: 20px 10px;
    margin: 0 auto;
    width: 100%;
    max-width: none;
  }
}

.CoveoSearchInterface .coveo-main-section {
  *zoom: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.CoveoSearchInterface .coveo-main-section:before,
.CoveoSearchInterface .coveo-main-section:after {
  content: " ";
  display: table;
}

.CoveoSearchInterface .coveo-main-section:after {
  clear: both;
}

.CoveoSearchInterface .coveo-facet-column {
  display: block;
  float: left;
  margin-right: 2.84259%;
  width: 28.01019%;
  padding: 5px 15px;
}

.CoveoSearchInterface .coveo-facet-column:last-child {
  margin-right: 0;
}

.CoveoSearchInterface .coveo-facet-column.coveo-no-results {
  display: none;
}

.CoveoSearchInterface .coveo-results-column {
  overflow: hidden;
  padding: 10px 10px 10px 15px;
}

.CoveoSearchInterface .coveo-results-column.coveo-no-results {
  max-width: 75%;
  margin: auto;
  text-align: center;
}

.CoveoSearchInterface .coveo-results-column .coveo-media-max-width-800.coveo-no-results {
  max-width: 100%;
}

@media (max-width: 800px) {
  .CoveoSearchInterface .coveo-results-column.coveo-no-results {
    max-width: 100%;
  }
}

.CoveoSearchInterface .coveo-results-column .coveo-media-max-width-480 {
  padding: 0;
}

@media (max-width: 480px) {
  .CoveoSearchInterface .coveo-results-column {
    padding: 0;
  }
}

.CoveoSearchInterface.coveo-during-initialization .coveo-results-header {
  height: 35px;
}

.CoveoSearchInterface.coveo-during-initialization .coveo-results-header * {
  display: none;
}

.CoveoSearchInterface.coveo-during-initialization .CoveoLogo,
.CoveoSearchInterface.coveo-during-initialization .CoveoResultsPerPage {
  display: none;
}

.coveo-after-initialization > * {
  display: inherit;
  visibility: inherit;
}

.coveo-after-initialization link,
.coveo-after-initialization script,
.coveo-after-initialization template {
  display: none;
}

.coveo-after-initialization output {
  display: inline;
}

.coveo-after-initialization meter,
.coveo-after-initialization progress,
.coveo-after-initialization input,
.coveo-after-initialization textarea,
.coveo-after-initialization keygen,
.coveo-after-initialization select,
.coveo-after-initialization button {
  display: inline-block;
}

.coveo-after-initialization table {
  display: table;
}

.CoveoSearchButton {
  color: white;
  border: 1px solid #bcc3ca;
  border-left: none;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  height: 50px;
  width: 60px;
  cursor: pointer;
  line-height: 0;
}

.CoveoSearchButton:before {
  content: '';
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.CoveoSearchButton .coveo-search-button,
.CoveoSearchButton .coveo-search-button-loading {
  vertical-align: middle;
  animation: none;
  display: inline-block;
}

.CoveoSearchButton .coveo-search-button-loading-svg {
  display: none;
}

.CoveoSearchButton:hover .coveo-magnifier-circle-svg {
  fill: $color-grey8;
}

.coveo-search-button-svg {
  width: 18px;
  height: 18px;
  color: $color-grey8;
}

.coveo-executing-query .CoveoSearchButton .coveo-search-button-svg {
  display: none;
}

.coveo-executing-query .CoveoSearchButton .coveo-search-button-loading-svg {
  display: inline;
  color: #1d4f76;
  width: 18px;
  height: 18px;
  -webkit-animation-name: coveo-spin;
  -moz-animation-name: coveo-spin;
  animation-name: coveo-spin;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.CoveoSearchInterface {
  min-height: 100%;
  position: relative;
  margin: 0;
  font-family: $base-font-family;
  font-size: 15px;
  color: #313a45;
}

.CoveoSearchInterface .coveo-highlight,
.CoveoSearchInterface .highlight {
  font-weight: bold;
}

.CoveoSearchInterface input[type='text']::-ms-clear {
  width: 0;
  height: 0;
}

.CoveoSearchInterface input:focus,
.CoveoSearchInterface textarea:focus,
.CoveoSearchInterface select:focus {
  outline: none;
}

.CoveoSearchInterface input[type='text'],
.CoveoSearchInterface textarea {
  color: #67768b;
}

.CoveoSearchInterface .coveo-tab-disabled {
  display: none;
}

.CoveoSearchInterface * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.CoveoSearchInterface .coveo-error {
  color: #dc291e;
}

.CoveoSearchInterface.coveo-hidden {
  display: none;
}

body.coveo-modalBox-opened,
body.coveo-modalBox-opened .CoveoSearchInterface {
  overflow: hidden;
}

.CoveoSearchInterface .coveo-modal-container.coveo-opened {
  display: flex;
}

.coveo-modalBox > .coveo-wrapper > .coveo-title {
  height: 40px;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
}

.CoveoSearchInterface.coveo-during-initialization .coveo-result-frame.coveo-placeholder-result {
  margin: 20px 0 20px 20px;
}

.CoveoSearchInterface.coveo-during-initialization .CoveoBreadcrumb {
  display: none;
}

.CoveoSearchInterface.coveo-during-initialization .CoveoHiddenQuery {
  display: none;
}

.CoveoSearchInterface.coveo-during-initialization .CoveoDidYouMean {
  display: none;
}

.CoveoSearchInterface.coveo-during-initialization .CoveoErrorReport {
  display: none;
}

.CoveoSearchInterface.coveo-during-initialization .coveo-results-header {
  height: 42px;
  visibility: hidden;
}

.CoveoSearchInterface.coveo-during-initialization .CoveoSimpleFilter {
  display: none;
}

.CoveoSearchInterface.coveo-during-initialization .coveo-recommendation-main-section .coveo-recommendation-column {
  display: none;
}

.CoveoSearchInterface.coveo-during-initialization .coveo-media-max-width-800 .coveo-results-header {
  height: 69px;
}

.CoveoSearchInterface.coveo-during-initialization .coveo-media-max-width-800 .coveo-facet-column {
  display: none;
}

@media (max-width: 800px) {
  .CoveoSearchInterface.coveo-during-initialization .coveo-results-header {
    height: 69px;
  }

  .CoveoSearchInterface.coveo-during-initialization .coveo-facet-column {
    display: none;
  }
}

.CoveoSearchInterface.coveo-during-initialization .coveo-media-max-width-1020 .coveo-recommendation-results-column {
  display: inherit;
  width: inherit;
}

@media (max-width: 1020px) {
  .CoveoSearchInterface.coveo-during-initialization .coveo-recommendation-results-column {
    display: inherit;
    width: inherit;
  }
}

.CoveoSearchbox.coveo-during-initialization {
  display: block;
  height: 50px;
  border: thin solid #bcc3ca;
  border-radius: 2px;
}

.coveo-visible-to-screen-reader-only {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.coveo-accessible-button:hover {
  cursor: pointer;
}

.coveo-accessible-button:focus {
  outline-width: 0;
}

.coveo-accessible-button.coveo-accessible-button-focused:focus {
  outline-width: initial;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-dropdown-content {
  z-index: 20;
  margin: 0;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-dropdown-header {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  box-ordinal-group: 2;
  -webkit-order: 2;
  -moz-order: 2;
  order: 2;
  -ms-flex-order: 2;
  border: thin solid #bcc3ca;
  border-radius: 2px;
  background-color: white;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-dropdown-header.coveo-dropdown-header-active {
  z-index: 20;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-dropdown-header.coveo-no-visible-facet {
  display: none;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content {
  overflow-y: scroll;
  max-height: 600px;
  clear: both;
  white-space: nowrap;
  padding: 0;
  min-width: 280px;
  width: 35%;
  z-index: 20;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 0 2px;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content .CoveoFacet,
.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content .CoveoFacetSlider,
.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content .CoveoFacetRange,
.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content .CoveoHierarchicalFacet {
  margin: 0;
  border: 0;
  border-radius: 0;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content .CoveoFacet .coveo-facet-header,
.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content .CoveoFacetSlider .coveo-facet-header,
.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content .CoveoFacetRange .coveo-facet-header,
.CoveoSearchInterface.coveo-small-facets .coveo-facet-column.coveo-facet-dropdown-content .CoveoHierarchicalFacet .coveo-facet-header {
  border: 0;
  border-radius: 0;
  border-top: thin solid #bcc3ca;
  white-space: initial;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-header-filter-by-container {
  background: #f7f8f9;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.22px;
  line-height: 22px;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-header-filter-by-container .coveo-facet-header-filter-by {
  display: block;
  padding: 10px 0 10px 20px;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-header-filter-by-container {
  background: #f7f8f9;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.22px;
  line-height: 22px;
}

.CoveoSearchInterface.coveo-small-facets .coveo-facet-header-filter-by-container .coveo-facet-header-filter-by {
  display: block;
  padding: 10px 0 10px 20px;
}

.magic-box {
  position: relative;
  text-align: left;
  color: #212121;
  border: 1px solid #9e9e9e;
}

.magic-box .magic-box-input {
  background: #fff;
  height: 48px;
  overflow: hidden;
  position: relative;
}

.magic-box .magic-box-input .magic-box-underlay,
.magic-box .magic-box-input > input {
  color: #212121;
  text-align: left;
  padding: 12px 0;
  text-indent: 12px;
  font-family: $base-font-family;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  white-space: pre;
  overflow: auto;
  box-sizing: border-box;
  display: block;
}

.magic-box .magic-box-input input {
  background: none;
  border: none;
  resize: none;
  outline: 0;
}

.magic-box .magic-box-input input::-ms-clear {
  width: 0;
  height: 0;
}

.magic-box .magic-box-input .magic-box-underlay {
  color: transparent;
  overflow: hidden;
}

.magic-box .magic-box-input .magic-box-underlay > span {
  text-indent: 0;
}

.magic-box .magic-box-input .magic-box-underlay span {
  display: inline-block;
  vertical-align: top;
}

.magic-box .magic-box-input .magic-box-underlay span[data-value=''] {
  height: 24px;
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-ghost-text {
  visibility: hidden;
  color: #bdbdbd;
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-error {
  border-bottom: solid #f44336 2px;
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-error.magic-box-error-empty {
  position: relative;
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-error.magic-box-error-empty:before {
  content: ' ';
  border-bottom: solid #f44336 2px;
  position: absolute;
}

.magic-box .magic-box-input .magic-box-underlay:after {
  content: '';
  display: inline-block;
}

.magic-box .magic-box-clear {
  position: relative;
  float: right;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 24px;
  transition: width 0.3s;
  cursor: pointer;
  background: #fff;
  width: 0;
  overflow: hidden;
}

.magic-box .magic-box-clear .magic-box-icon:before {
  color: #9e9e9e;
  font-family: $base-font-family;
  content: 'X';
  font-size: 24px;
}

.magic-box .magic-box-clear:before {
  content: '';
  border-left: #e0e0e0 1px solid;
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: 0;
}

.magic-box.magic-box-notEmpty .magic-box-clear {
  width: 48px;
}

.magic-box.magic-box-hasFocus .magic-box-underlay .magic-box-ghost-text {
  visibility: visible;
}

.magic-box.magic-box-hasFocus .magic-box-suggestions.magic-box-hasSuggestion {
  display: block;
}

.magic-box .magic-box-suggestions {
  clear: both;
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  overflow: hidden;
  background: #fff;
  z-index: 1;
  display: none;
  font-family: $base-font-family;
  border: 1px solid #9e9e9e;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator,
.magic-box .magic-box-suggestions .magic-box-suggestion {
  opacity: 1;
  transition: opacity 0.3s;
}

.magic-box .magic-box-suggestions.magic-box-suggestions-loading .magic-box-suggestion-seperator,
.magic-box .magic-box-suggestions.magic-box-suggestions-loading .magic-box-suggestion {
  opacity: 0;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator {
  padding: 12px 12px 0;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
  height: 36px;
  border-top: 1px solid #e0e0e0;
  color: #9e9e9e;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator:first-child {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator .magic-box-suggestion-seperator-label {
  float: left;
  margin-right: 12px;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator:after {
  content: '';
  display: block;
  position: relative;
  top: 50%;
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator + .magic-box-suggestion {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion {
  cursor: pointer;
  line-height: 24px;
  padding: 12px;
  font-size: 16px;
  background: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid #fafafa;
  color: #212121;
}

.magic-box .magic-box-suggestions .magic-box-suggestion .magic-box-hightlight {
  font-weight: bold;
  color: #000;
}

.magic-box .magic-box-suggestions .magic-box-suggestion:first-child {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion.magic-box-selected {
  background: #e0f7fa !important;
}

.magic-box .magic-box-suggestions .magic-box-suggestion:hover {
  background: #f5f5f5;
}

.magic-box.magic-box-inline {
  overflow: hidden;
}

.magic-box.magic-box-inline .magic-box-suggestions.magic-box-hasSuggestion {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  border: none;
  border-top: 1px solid #9e9e9e;
}

.coveo-waiting-for-first-query .coveo-omnibox {
  z-index: 0;
}

.CoveoOmnibox.magic-box .coveo-omnibox-hightlight {
  font-weight: bold;
  color: #000;
}

.CoveoOmnibox.magic-box .magic-box-suggestions {
  z-index: 3;
}

.CoveoOmnibox.magic-box .coveo-omnibox-hightlight2 {
  font-weight: bold;
  font-style: italic;
  color: #000;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='true'],
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='true'] {
  position: relative;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='true']:before,
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='true']:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #c8e6c9;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='true']:before,
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='true']:before {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: -1px;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='true']:before,
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='true']:before {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  right: -1px;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='false'] > [data-success='true'],
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='false'] > [data-success='true'] {
  position: relative;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='false'] > [data-success='true']:before,
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='false'] > [data-success='true']:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffecb3;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='false'] > [data-success='true'][data-id='FieldQuery_0'],
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='false'] > [data-success='true'][data-id='FieldQuery_0'] {
  position: relative;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='false'] > [data-success='true'][data-id='FieldQuery_0']:before,
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='false'] > [data-success='true'][data-id='FieldQuery_0']:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffecb3;
}

.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldQuery'][data-success='false'] > [data-success='true'][data-id='FieldQuery_0']:before,
.CoveoOmnibox.magic-box .magic-box-underlay [data-id='FieldSimpleQuery'][data-success='false'] > [data-success='true'][data-id='FieldQuery_0']:before {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: -1px;
}

.CoveoOmnibox.magic-box.coveo-search-as-you-type .magic-box-ghost-text {
  display: none;
}

.CoveoOmnibox.magic-box .coveo-omnibox-selectable {
  cursor: pointer;
}

.CoveoOmnibox.magic-box .coveo-omnibox-selectable.coveo-omnibox-selected {
  background-color: #e6ecf0;
}

.CoveoOmnibox.magic-box .coveo-omnibox-facet-value {
  width: 100%;
  display: inline-block;
  float: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px;
  margin: 0;
}

.CoveoOmnibox.magic-box .coveo-omnibox-facet-value li.coveo-facet-value {
  list-style-type: none;
}

.CoveoOmnibox.magic-box .coveo-omnibox-facet-value .coveo-facet-value-label-wrapper > * {
  vertical-align: middle;
}

.CoveoOmnibox.magic-box .coveo-omnibox-facet-header {
  float: left;
  width: 100%;
  padding: 5px;
  height: 26px;
  color: #2c5985;
}

.CoveoOmnibox.magic-box .coveo-omnibox-facet-header .coveo-icon {
  margin-right: 6px;
  vertical-align: text-bottom;
}

.CoveoOmnibox.magic-box .coveo-omnibox-section {
  vertical-align: middle;
  margin-bottom: 2px;
}

.CoveoOmnibox.magic-box .coveo-omnibox-header {
  height: 26px;
  color: #0065cb;
  margin-top: 4px;
  margin-bottom: 2px;
}

.CoveoOmnibox.magic-box .coveo-omnibox-header .coveo-text {
  padding: 6px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CoveoResult.coveo-omnibox-selectable {
  padding: 10px 10px 10px 15px;
  margin: 0;
}

.coveo-list-layout.CoveoResult.coveo-omnibox-selectable,
.coveo-card-layout.CoveoResult.coveo-omnibox-selectable,
.coveo-table-layout.CoveoResult.coveo-omnibox-selectable {
  padding: 10px 10px 10px 36px;
  margin: 0;
  box-sizing: border-box;
}

.coveo-list-layout.CoveoResult.coveo-omnibox-result-list-element.coveo-omnibox-selectable.coveo-omnibox-selected,
.coveo-card-layout.CoveoResult.coveo-omnibox-result-list-element.coveo-omnibox-selectable.coveo-omnibox-selected,
.coveo-table-layout.CoveoResult.coveo-omnibox-result-list-element.coveo-omnibox-selectable.coveo-omnibox-selected {
  padding: 7px 7px 8px 33px;
  background-color: white;
  border: thin solid #bcc3ca;
  border-width: 3px;
}

.CoveoResult.coveo-omnibox-selectable {
  padding: 10px 10px 10px 36px;
  margin: 0;
  box-sizing: border-box;
}

.CoveoResult.coveo-omnibox-result-list-element.coveo-omnibox-selectable.coveo-omnibox-selected {
  padding: 7px 7px 8px 33px;
  background-color: white;
  border: thin solid #bcc3ca;
  border-width: 3px;
}

.magic-box-suggestions .coveo-card-layout-container {
  margin-right: 0;
}
.coveo-omnibox-result-list-header {
  width: 100%;
  padding: 10px;
  color: #1d4f76;
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-header {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  box-ordinal-group: 1;
  -webkit-order: 1;
  -moz-order: 1;
  order: 1;
  -ms-flex-order: 1;
  border: thin solid #bcc3ca;
  border-radius: 2px;
  background-color: white;
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-header.coveo-dropdown-header-active {
  background-color: #e6ecf0;
  border-color: #009ddc;
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-content {
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 300ms;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 300ms;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 300ms;
  overflow: hidden;
  max-height: 0;
  width: 100%;
  padding: 0;
  margin: 25px 0 15px;
  border-bottom: 0 solid #e6ecf0;
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-content .coveo-media-max-width-480 {
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 400ms;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 400ms;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 400ms;
}

@media (max-width: 480px) {
  .CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-content {
    -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 400ms;
    -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 400ms;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 400ms;
  }
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-content.coveo-recommendation-column,
.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-content.CoveoRecommendation {
  display: block;
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-content.coveo-open-dropdown-content {
  max-height: 1000px;
  width: 100%;
  border-bottom: 5px solid #e6ecf0;
}

.CoveoSearchInterface.coveo-small-recommendation .CoveoRecommendation,
.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-facet-column,
.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-dropdown-container {
  padding: 0;
}

.CoveoSearchInterface.coveo-small-recommendation .CoveoRecommendation {
  border: none;
}

.CoveoSearchInterface.coveo-small-recommendation .CoveoResultlist .CoveoResult:first-child {
  border-top: thin solid #bcc3ca;
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-header {
  padding: 5px 0;
  background-color: #e6ecf0;
  border-bottom: thin solid #bcc3ca;
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-title {
  color: #67768b;
}

.CoveoSearchInterface.coveo-small-recommendation #coveo-recommendation-results-column,
.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-results-column,
.CoveoSearchInterface.coveo-small-facets #coveo-recommendation-results-column,
.CoveoSearchInterface.coveo-small-facets .coveo-recommendation-results-column {
  width: auto;
  display: block;
}

.CoveoSearchInterface.coveo-small-recommendation .coveo-recommendation-facet-column,
.CoveoSearchInterface.coveo-small-facets .coveo-recommendation-facet-column {
  width: 30%;
}

.CoveoSearchInterface .coveo-recommendation-results-column {
  width: 49%;
  display: inline-block;
}

#coveo-recommendation-results-column.coveo-no-results,
.coveo-recommendation-results-column.coveo-no-results,
.CoveoSearchInterface .coveo-recommendation-results-column.coveo-no-results {
  width: inherit;
  display: block;
}

#coveo-recommendation-facet-column,
.coveo-recommendation-facet-column,
.CoveoSearchInterface .coveo-recommendation-facet-column {
  display: inline-block;
  vertical-align: top;
}

#coveo-recommendation-facet-column.coveo-no-results,
.coveo-recommendation-facet-column.coveo-no-results,
.CoveoSearchInterface .coveo-recommendation-facet-column.coveo-no-results {
  display: none;
}

#coveo-recommendation-main-section,
.coveo-recommendation-main-section,
.CoveoSearchInterface .coveo-recommendation-main-section {
  max-width: 1450px;
}

#coveo-recommendation-main-section.coveo-no-results .coveo-recommendation-column,
.coveo-recommendation-main-section.coveo-no-results .coveo-recommendation-column,
.CoveoSearchInterface .coveo-recommendation-main-section.coveo-no-results .coveo-recommendation-column {
  display: none;
}

.CoveoSearchbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.CoveoSearchbox.coveo-inline {
  overflow: hidden;
}

.CoveoSearchbox.coveo-inline .magic-box {
  overflow: visible;
}

.CoveoSearchbox .CoveoSearchButton {
  z-index: 2;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.CoveoSearchbox .magic-box {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-positive: 1;
  border: thin solid #bcc3ca;
  border-radius: 2px;
}

.CoveoSearchbox .magic-box .magic-box-clear-svg {
  width: 15px;
  height: 15px;
  color: #1d4f76;
}

.CoveoSearchbox .magic-box .magic-box-clear:before {
  border-left: none;
}

.CoveoSearchbox .magic-box .magic-box-clear .magic-box-icon:before {
  content: '';
}

.CoveoSearchbox .magic-box .magic-box-input {
  border-radius: 2px;
}

.CoveoSearchbox .magic-box .magic-box-input > input {
  color: #67768b;
}

.CoveoSettings ~ .CoveoSearchbox {
  margin-right: 76px;
}

.CoveoSearchInterface.coveo-waiting-for-query .CoveoSearchbox {
  margin: 0;
}

.CoveoSearchInterface.coveo-waiting-for-query .CoveoSearchbox .coveo-media-max-width-480 {
  margin: 0 20px;
}

@media (max-width: 480px) {
  .CoveoSearchInterface.coveo-waiting-for-query .CoveoSearchbox {
    margin: 0 20px;
  }
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-ghost-text,
.magic-box.magic-box-hasFocus .magic-box-input .magic-box-underlay .magic-box-ghost-text {
  display: none;
}

.coveo-query-syntax-disabled.magic-box .magic-box-input .magic-box-underlay span,
.coveo-query-syntax-disabled .magic-box-highlight-container {
  display: none;
}

.CoveoResult.coveo-omnibox-selectable {
  padding: 10px 10px 10px 15px;
  margin: 0;
}

.CoveoCategoryFacet .coveo-accessible-button {
  outline: none;
}

/*CoveoFullSearch.css CoveoSearch.TI.custom.css*/
.CoveoSearchInterface .coveo-search-section {
    margin: 30px 0 0 0;
    padding: 0 10px;
    max-width:300px;
}

.CoveoSearchButton .coveo-search-button, .CoveoSearchButton .coveo-search-button-loading {
    animation: none;
    display: inline-block;
    margin-top: 12px;
}

#searchboxheader .coveo-search-section {
    width: 30em;
    margin: 0;
    padding: 0 10px;
    max-width:none;
}

.CoveoSearchButton:before {
    float: left;
}

.CoveoSearchButton {
    height: 36px;
    border: none;
    width: 40px;
}
.coveo-search-button-svg {
    width: 12px;
    height: 12px;
    color: $color-grey8;
}

.magic-box .magic-box-input .magic-box-underlay, .magic-box .magic-box-input > input {
    padding: 0;
    text-indent: 12px;
    line-height: normal;
    height: 36px;
 }

.magic-box .magic-box-input {
 	height: 36px;
 }

.magic-box.magic-box-notEmpty .magic-box-clear {
    width: 28px;
}

.CoveoSearchInterface * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.magic-box .magic-box-clear {
    position: relative;
    float: right;
    height: 36px;
    line-height: 26px;
    text-align: center;
    font-size: 24px;
    transition: width 0.3s;
    cursor: pointer;
    background: #fff;
    width: 0;
    overflow: hidden;
}

.CoveoSearchInterface.coveo-waiting-for-query .coveo-search-section {
    margin-top: 40px;
}

.CoveoSearchbox .magic-box {
    border: none;
}

.CoveoSearchbox .magic-box .magic-box-clear-svg {
    width: 8px;
    height: 8px;
    color: $color-grey8;
}

.CoveoSearchbox {
    border: thin solid #bcc3ca;
}

/* stylelint-enable */
