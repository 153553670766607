/**
* ==========================================================================
* _object.mediabox.scss
* This is the common media box object styles
* Used for Featured Overivew, Featured Product, etc.
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* 1.1 Common layout
* --------------------------------------------------------------------------
*/

/* box container */

.ti_box {
  padding-bottom: 1rem;
}

/* box elements */

.ti_box-image {
  padding: 1rem 1rem 0 1rem;
  text-align: center;
}

.ti_box-image img {
  max-width: 100%;
  vertical-align: text-bottom; // removes bottom gap on <a> wrapped image ?
}

// toggled modifier for full bleed image
.ti_box-image.mod-fullbleed {
  padding: 0;
}

.ti_box-image.mod-left {
  padding: 0 1rem 0 0;
}

.ti_box-image.mod-right {
  padding: 0 0 1rem 0;
}

.ti_box-content,
.ti_box-title,
.ti_box-cta {
  padding: 0 1rem;
}

.ti_box-content p {
  max-width: 100%;
}

.ti_box-title {
  margin: 0 0 1em 0;
  padding-top: 1em;
  text-align: center;
  font-size: 1.2em;
}

.ti_box-title:empty {
  margin-bottom: 0.25rem;
}

.ti_box-content > .ti_box-title {
  padding: 1rem 0 0 0;
}

.ti_box-title.mod-large {
  font-size: 1.6em;
}

.ti_box-title.mod-small {
  font-size: 1em;
}

.ti_box-title a {
  color: inherit;
}

.ti_box .ti-label {
  padding: 1em 1rem 0 1rem; // give correct padding to label in this box context
}

.ti_box-subtitle {
  padding-top: 0.4em;
}

.ti_box-cta a {
  display: inline-block;
  white-space: normal;
}

.ti_box-cta .u-flex-row {
  justify-content: space-between;
}

.ti_box-cta-link {
  padding: 0.5em 0;
}

.ti_box-cta-button {
  padding-top: 1em;
}

.ti_box-condensed {
  margin-bottom: 1rem;
  padding: 0;
}

.ti_box-condensed .ti_box-image {
  min-width: 100px;
  max-width: 150px;
  max-height: 200px; //needed for IE issue
  margin-right: 1rem;
  padding: 0;
}

.ti_box-condensed .ti_box-title {
  margin: 0;
  padding: 0 0 0 1rem;
  align-self: center;
}

.ti_box-literature {

  .ti_box-title {
    margin-bottom: 1rem;
  }

  .ti_box-title,
  .ti_box-cta-link,
  p {
    padding-left: 0;
  }
}

.ti_box-literature-left {
  float: left;
  width: 60%;
}

.ti_box-literature-right {
  float: right;
  width: 40%;
}

/**
* --------------------------------------------------------------------------
* 1.2 Layout adjustments for same height rows
* todo!: Adjust based on how rows are handled in AEM - kayin
* --------------------------------------------------------------------------
*/

/* same height adjustments */

.u-sameheight {
  display: flex;
  flex-direction: row;
}

.u-sameheight .ti_box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.u-sameheight .ti_box-content {
  flex: 1 1 auto;
}

/**
* --------------------------------------------------------------------------
* 1.3 Style variation modifiers
* --------------------------------------------------------------------------
*/

/* style 1 modifier (teal line) */

.ti_box.mod-style1 {
  border-top: 2px solid $color-teal5;
  background-color: $color-grey1;
  box-shadow: $global-boxShadow-1;
}

/* style 2 modifier (red line) */

.ti_box.mod-style2 {
  border-top: 2px solid $color-red5;
  background-color: $color-white;
  box-shadow: $global-boxShadow-1;
}

/* style 3 modifier (white bg, light border) */

.ti_box.mod-style3 {
  text-align: center;
  border: 1px solid $color-grey2;
  box-shadow: none;
}

.ti_box.mod-style3 .ti_box-content {
  text-align: left;
}

.ti_box.mod-style3 .ti_divider.mod-alt {
  display: none;
  margin: 1rem 2rem 0.5rem 2rem;
  background-image: linear-gradient(to right, rgba($color-red5, 0), rgba($color-red5, 0.75), rgba($color-red5, 0));
}

.ti_box.mod-style3 .ti_box-title {
  display: inline-block;
  margin: 0 1rem 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-red5;
}

/* style 4 modifier (no box, white bg) */

.ti_box.mod-style4 {
  padding-bottom: 0;
  border-top: none;
  box-shadow: none;

  .ti_box-title {
    padding: 1rem 0 0 0;
  }

  .ti_box-image,
  .ti_box-content,
  .ti_box-cta {
    padding: 0;
  }

  .ti_box-condensed ~ .ti_box-content {
    padding: 0;
  }

  .ti_box-condensed ~ .ti_box-cta {
    padding: 0;
  }

  .ti_box-condensed .ti_box-title {
    padding: 0;
  }
}

/* grey top border modifier - EERD, Quick search */

.ti_box.mod-borderTop-grey {
  border-top: 2px solid $color-grey6;
  background-color: $color-white;
  min-height: 400px;

  > div {
    padding: 10px 15px;
  }

  &.mod-bg-grey {
    background-color: $color-grey1;
  }
}

// to kill sameheight on condensed boxes
.u-sameheight .ti_box.mod-style4 > .ti_box-condensed + .ti_box-content {
  flex: none;
}

/* style 5 mod (grey bg, light shadow - literature list) */

.ti_box.mod-style5 {
  background-color: $color-grey1;
  box-shadow: $global-boxShadow-1;
}

// .ti_box.mod-style5 .ti_box-cta-button a {
//   background-color: $color-white;
// }

/* style 6 mod (grey bg, grey border - link list) */

.ti_box.mod-style6 {
  border: 1px solid $color-grey2;
  background-color: $color-grey1;
}

// .ti_box.mod-style6 .ti_box-cta-button a {
//   background-color: $color-white;
// }

/* style 6 mod for omitted heading/description */

/* stylelint-disable no-descending-specificity */

.ti_box.mod-style6 .ti_box-content {

  p:first-of-type {
    margin-top: 1em;
  }

  ul:first-of-type {
    margin-top: 1em;

    &:first-child {
      border-top: none;
    }
  }
}

.ti_box.mod-style6 .ti_box-title:empty {
  display: none;
}

/* style 7 - grey */
.ti_box.mod-style7 {
  border-top: 2px solid $color-grey7;
  background-color: $color-white;
}

.ti_box.mod-style8 {
  border-top: 2px solid $color-grey7;
  background-color: $color-grey1;
}

/* stylelint-enable */
