/**
* ==========================================================================
* _object.messagebox.scss
* This is the messagebox object
* ==========================================================================
*/

.ti_message-box {
  display: flex;
  overflow: hidden;
  min-height: 7em;
  margin: 2rem 0;
  padding: 1rem 1.5rem;
  border-left: 4px solid $color-grey8;
  background-color: $color-grey1;
  position: relative;
}

.ti_message-box > .ti_icon {
  margin-right: 1rem;
  margin-top: 0.1rem;
}

.ti_message-box.mod-notice {
  border-color: $color-teal5;
}

.ti_message-box.mod-warning {
  border-color: $color-red5;
}

.ti_message-box.mod-alert {
  border-color: $color-yellow4;
}

.ti_message-box.mod-success {
  border-color: $color-green;
}

.ti_message-box::before {
  display: none;
}

.ti_message-box h3,
.ti_message-box p,
.ti_message-box ul,
.ti_message-box ol {
  margin: 0 0 0.8rem 0;
}

.ti_message-box ul,
.ti_message-box ol {
  list-style-position: inside;
}

.ti_message-box :last-child {
  margin: 0;
}

.ti_message-box .ti_message-box-close {
  position: absolute;
  display: block;
  top: 0.5rem;
  right: 0.5rem;
  color: $color-grey7;
  width: 2em;
  height: 2em;
  line-height: 2em;
  font-size: 1em;
  text-align: center;
  border-radius: 50%;

  &:hover {
    text-decoration: none;
    color: $color-red5;
    background-color: $color-red2;

    .ti_icon {
      fill: $color-grey6;
    }
  }
}

.ti_message-box .close {
  float: right;
  margin: -0.5rem -0.5rem 0 0;
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.3em;

  &:hover {
    background: $color-grey7;
  }

  &::before {
    content: '\2715'; // x multiplication: &#10005;
  }
}
