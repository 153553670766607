/**
* ==========================================================================
* _object.rssfeed.scss
* This is the rss/blog feed component
* (ex. www.ti.com/power-management/linear-regulators-ldo/overview.html)
* ==========================================================================
*/

.e2eBlogForum h3 {
  margin-bottom: 0;
}

.ti_rssfeed-item {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 1em;
  border-bottom: 1px dotted $color-grey5;
}

.ti_rssfeed-title {
  display: block;
  clear: both;
  margin-bottom: 0.5em;
  font-size: 1.2em;
}

.ti_rssfeed-date {
  display: inline-block;
  float: left;
  padding-right: 1em;
  color: $color-grey5;
  font-size: 0.9em;
}

.ti_rssfeed-desc {
  margin-bottom: 0.5em;
  color: $color-grey7;
  font-size: 0.9em;
}

.ti_rssfeed-image {
  min-width: 33.33%;
  text-align: center;
}

.ti_rssfeed-image img {
  max-height: 6em;
}

.ti_rssfeed-list {
  margin-bottom: 0;
}

.e2eBlogForum .ti_divider {
  margin: 1em 0;
}
