/**
* --------------------------------------------------------------------------
* _element.divider.scss
* Common horizontal rule element
* --------------------------------------------------------------------------
*/

.ti_divider {
  margin: 4rem 0 2rem 0;
}

.ti_divider.mod-primary {
  position: relative;
  height: 1em;
  border: 0;
}

.ti_divider.mod-primary::before,
.ti_divider.mod-primary::after {
  position: absolute;
  width: 100%;
  content: '\00a0'; // non breaking space
}

.ti_divider.mod-primary::before {
  top: -1em;
  height: 2em;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
}

.ti_divider.mod-primary::after {
  top: -1em;
  height: 1em;
  background: $color-white;
}

.divider-secondary, // legacy! name
.ti_divider.mod-secondary {
  height: 1px;
  margin: 1rem 0 1rem 0;
  border: 0;
  border-bottom: $global-line-dotted;
  background-image: none;
}

.borderLeft, // legacy! name
.ti_divider-vertical {
  border-left: $global-line-dotted;
  margin-left: 1rem;
  padding-left: 1rem;
}

.ti_divider-vertical:first-child {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
}

.ti_divider.mod-alt { // alternate style still used in blog area
  height: 1px;
  border: 0;
  background-image: linear-gradient(to right, rgba($color-black, 0), rgba($color-black, 0.5), rgba($color-black, 0));
  margin: -0.5rem 0 1rem 0;
}
