/**
* ==========================================================================
* _element.icon.scss
* Common SVG icon element
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* SVG icon element
* --------------------------------------------------------------------------
*/

/* svg icon configuration */

// icon color namespace and values
$icons-colors-map: (
  'color1':   $color-grey7, // #555555; default
  'color2':   $color-teal6, // #115566
  'color3':   $color-red5,  // #cc0000
  'disabled': $color-grey3, // #cccccc
  'reversed': $color-white  // #ffffff
) !default;

// icon sizes namespace and values
$icons-sizes-map: (
  's':  18px,
  'm':  24px, // default
  'l':  36px,
  'xl': 48px
) !default;

// icon defaults
$icons-default-color: map-get($icons-colors-map, color1) !default; // #555555
$icons-default-size: map-get($icons-sizes-map, m) !default;        // 24px

/* svg sprite icon container */

.ti_icon {
  display: inline-block;
  vertical-align: middle;
  fill: $icons-default-color;  // #555555

  .ti_icon-readerText {
    @include mix-hidden-visually;
  }
}

/* svg icon img */

.ti_icon svg {
  display: block;
  width: $icons-default-size;  // 24px
  height: $icons-default-size; // 24px
  stroke-width: 0;
}

/* svg icon size modifiers */

@each $iconSize-namespace, $iconSize-property in $icons-sizes-map {

  .ti_icon.mod-size-#{$iconSize-namespace} svg {
    width: $iconSize-property;
    height: $iconSize-property;
  }
}

/* svg icon color modifiers */

@each $iconColor-namespace, $iconColors-property in $icons-colors-map {

  .ti_icon.mod-#{$iconColor-namespace} {
    fill: $iconColors-property;
  }
}

/* modifier for circle'd icon */

.ti_icon.mod-circle {
  border-radius: 100%;

  svg {
    transform: scale(0.75);
  }
}

/* if circle wrapper mod is used, color bg accordingly */

@each $iconColor-namespace, $iconColors-property in $icons-colors-map {

  .ti_icon.mod-circle.mod-#{$iconColor-namespace} {
    background-color: $iconColors-property;
    fill: map-get($icons-colors-map, reversed);
  }
}

/**
* --------------------------------------------------------------------------
* SVG CSS background images styles
* These are basic styles for individual css icon use
* --------------------------------------------------------------------------
*/

/* base icon bg styles */

.ti-iconBg {
  background-repeat: no-repeat;
  background-position: 0 0; /* top left position */
}

/* icon size modifiers */

@each $iconSize-namespace, $iconSize-property in $icons-sizes-map {

  .ti-iconBg.mod-size-#{$iconSize-namespace} {
    background-size: $iconSize-property;
    min-width: $iconSize-property;
    min-height: $iconSize-property;
    line-height: $iconSize-property;
  }

  .ti-iconBg.mod-size-#{$iconSize-namespace} span {
    margin-left: $iconSize-property + 4px;
  }
}

/* Background position adjustments for each color at each size */

@each $iconSize-namespace, $iconSize-property in $icons-sizes-map {

  @each $iconColor-namespace, $iconColor-property in $icons-colors-map {

    @if index(($icons-colors-map), ($iconColor-namespace $iconColor-property)) * ($iconSize-property) - ($iconSize-property) >= 1 {
      // only do this if the value isn't the default 'positon: 0 0'.

      .ti-iconBg.mod-size-#{$iconSize-namespace}.mod-#{$iconColor-namespace} {
        // this uses the current index position of the color map to multiply the current icon size, and subtracts the current icon size because
        // index in sass starts at 1 not 0, and the first color is at position: 0 0.
        background-position: 0 (-(index(($icons-colors-map), ($iconColor-namespace $iconColor-property)) * ($iconSize-property) - ($iconSize-property)));
      }
    }
  }
}
