/**
* ==========================================================================
* _object.imagearea.scss
* This is the image area object, includes figcaption, and zoom button
* ==========================================================================
*/

.ti_imagearea {
  position: relative;
  width: 100%;
  margin: 0 0 1rem 0;
  padding: 0;
  text-align: center;

  figcaption {
    max-width: 100%;
    margin: 1em 0 0 0;
    padding-bottom: 1em;
    text-align: left;
    color: $color-grey6;
    border-bottom: $global-line-dotted;
    font-size: 0.8em;

    p {
      margin-bottom: 0;
    }

    p:empty {
      display: none;
    }

    &:empty {
      display: none;
      border-bottom: none;
    }
  }

  .button-zoom {
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;

    > img {
      width: 100%;
    }

    .ti_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 48px;
      height: 48px;
      transform: translate(-50%, -50%);
      opacity: 0.75;
      background-color: $color-grey7;
      line-height: 48px;
    }

    &:hover {
      .ti_icon {
        opacity: 1;
      }
    }
  }
}
