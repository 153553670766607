/**
* ==========================================================================
* _element.tooltip.scss
* This is styles for form tool tip element
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Updated Tooltip hover and toggle with carrot
* --------------------------------------------------------------------------
*/

.ti-tooltip-content {
  position: absolute;
  display: none;
  white-space: pre-wrap;
  max-width: 350px;
  min-width: 250px;
  padding: $global-space-m;
  font-size: 1em;
  font-weight: 400;
  line-height: 18px;
  color: $color-grey8;
  background-color: $color-white;
  border-radius: 0;
  box-shadow: $global-boxShadow-2;
  transform: translate(-12px, -10px);

  /* carrot */
  &::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $color-white;
    border-top: 0;
    transform: rotate(-90deg);
    top: 16px;
    left: -11px;
    content: '\00a0';
    display: inline-block;
    height: 0;
    width: 0;
    position: absolute;
  }

  /* carrot shadow */
  &::before {
    position: absolute;
    display: inline-block;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #dddddd;
    border-top: 0;
    transform: rotate(-90deg);
    top: 16px;
    left: -13px;
    content: '\00a0';
    height: 0;
    width: 0;
  }
}

.ti-tooltip-trigger {
  position: relative;
  z-index: 2;

  &.mod-hover,
  &.mod-click {
    cursor: pointer;
  }
}

%tooltip-open {
  + .ti-tooltip-content {
    display: inline-block;
    z-index: 3;
    opacity: 1;
    transform: translate(12px, -10px);
    animation: tooltip-show $animation-easing-decelerate $animation-timing-enter;
  }
}

.ti-tooltip-trigger.mod-hover:hover,
.ti-tooltip-trigger.mod-focus:focus,
.ti-tooltip-checkbox:checked {
  @extend %tooltip-open;
}

@keyframes tooltip-show {
  0% {
    pointer-events: none; // Prevents the stuttering when mouse hovers over the trigger
    transform: translate(-12px, -10px);
    opacity: 0;
  }

  99% {
    pointer-events: initial;
  }

  100% {
    transform: translate(12px, -10px);
    opacity: 1;
  }
}

/**
* --------------------------------------------------------------------------
* legacy! tooltip style
* --------------------------------------------------------------------------
*/

.tooltip {

  .tooltip-content {
    position: absolute;
    background: $color-teal5;
    // border-radius: 0.2em;
    color: $color-white;
    font-size: 0.9em;
    padding: 0.5em 1em;
    opacity: 0;
    max-width: 500px;
    max-height: 0;
    margin: 0;
    transition: opacity 0.2s, max-height 0.2s, margin 0.2s;
  }

  a,
  img {

    + .tooltip-content {
      margin-top: 0.5em;
      z-index: -1;
    }
  }

  input:focus,
  textarea:focus,
  a:hover,
  img:hover {

    + .tooltip-content {
      opacity: 1;
      max-height: 200px;
      margin-left: 1.5em;
      z-index: 1;
    }
  }
}
