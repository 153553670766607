/**
* ==========================================================================
* _generic.common.scss
* Common global generic element styles
* This may just go in `_generic.reset.scss` instead.
* ==========================================================================
*/

/**
* --------------------------------------------------------------------------
* Global link styles
* --------------------------------------------------------------------------
*/

a {
  color: $global-link-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline-width: 1px !important;
  }
}

/**
* --------------------------------------------------------------------------
* Layout / Grid
* --------------------------------------------------------------------------
*/

hr {
  margin: 0 0 1rem 0;
  border: 0;
  border-bottom: 1px solid $color-grey2;
}

p {
  margin: 0 0 1rem 0;
  //max-width: 60em; opinionated max width for paragraph blocks.
}

p:empty {
  display: none; // kill empty paragraphs caused by AEM
}

h1 {
  margin: 0 0 2rem 0;
}

h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1rem 0;
}

// Page layout styles

.pageGroup {
  //min-width: 1000px;
  //max-width: 1300px;
  width: $page-min-width;
  // margin: 0;
  // padding: 0;
}

.pageContent {
  //margin: 0;
  //padding: 0;
}

.pageContent > .content {
  margin-left: 2rem;
}

.portal {
  display: inline-block;
  width: 80%;

  .portal {
    width: 100%;
  }

  .content {
    margin-left: 2rem;
  }

  > .content {
    clear: both;
    margin-left: 2rem;

    .ti_tabs,
    .ti_subTabNav {
      margin-left: 0;
    }
  }
}

// page header title (AEM)
// this used to be a inconvienient global h2:first-of-type declaration

/* stylelint-disable */

h2[data-lid]:first-of-type, // data-lid="portalTitle"
.breadcrumb + div > h2 { // adding specificity to selector - kayin
  margin-left: $global-space-xl;
  margin-bottom: 1rem;

  a:link, a:hover, a:active, a:visited {
    color: $alt-link-color;
  }
}

/* stylelint-enable */

// form elements adjust

[type='checkbox'], [type='radio'] {
  margin: 0.2em 0.25em 0 0.25em;
}

// quick search container margin

.ti-quick-search {
  margin-bottom: 2rem;
}
