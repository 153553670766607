/**
* --------------------------------------------------------------------------
* _element.button.scss
* Common button element
*
* cleaned up (converted to scss) by Kayin: 7/12/2018
* --------------------------------------------------------------------------
*/

/**
* --------------------------------------------------------------------------
* Common button element styles map
* --------------------------------------------------------------------------
*/

/*
 * This map contains the style values for button elements:
 * $styleName: ($textColor, $backgroundColor, $borderColor, $fontWeight, $hover-backgroundColor, $hover-borderColor)
 */
$buttons-style-map: (
  'default':   ($color-teal5, $color-white, $color-grey4, $base-font-weight, $color-white, $color-teal5),      // default style
  'nostyle':   ($color-teal5, transparent, transparent, $base-font-weight, $color-grey2, $color-grey2),        // no style
  'primary':   ($color-white, $color-red5, $color-red5, $base-font-weight-bold, $color-red4, $color-red4),     // red
  'secondary': ($color-white, $color-teal6, $color-teal6, $base-font-weight-bold, $color-teal5, $color-teal5), // teal
  'tertiary':  ($color-white, $color-grey8, $color-grey8, $base-font-weight-bold, $color-grey7, $color-grey7), // carbon
  'disabled':  ($color-white, $color-grey3, $color-grey3, $base-font-weight-bold, $color-grey3, $color-grey3)  // light grey
) !default;

/**
* --------------------------------------------------------------------------
* Common button element style
* --------------------------------------------------------------------------
*/

/*
 * This is the placeholder for common button element styles
 */
%element-button {
  appearance: none;
  display: inline-block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: $global-radius;
  font-size: 1em;
  line-height: 1.5em;
  transition: background-color $animation-timing-selectionControls linear;

  &:hover, &:focus {
    text-decoration: none;
    outline: none;
  }

  &.has-focusable:focus {
    animation: focus-highlight linear 1s infinite alternate;
    outline: none;
  }

  .ti_icon {
    margin-right: 0.5rem;
  }
}

/*
 * This mixin sets up the colors used for button variations
 * Usage: @include mix-element-button($styleName);
 * Valid $name values are the keys of $buttons-style-map.
 */
@mixin mix-element-button($styleName) {

  $style: map-get($buttons-style-map, $styleName);

  $textColor:             nth($style, 1);
  $backgroundColor:       nth($style, 2);
  $borderColor:           nth($style, 3);
  $fontWeight:            nth($style, 4);
  $hover-backgroundColor: nth($style, 5);
  $hover-borderColor:     nth($style, 6);

  @extend %element-button;

  color: $textColor;
  background-color: $backgroundColor;
  border-color: $borderColor;
  font-weight: $fontWeight;

  &:hover, &:focus {

    @if $styleName == disabled {
      cursor: default;
    }

    background-color: $hover-backgroundColor;
    border-color: $hover-borderColor;
  }
}

/**
* --------------------------------------------------------------------------
* Button element style variations
* --------------------------------------------------------------------------
*/

.button, /* legacy! classname */
.btn1, /* legacy! classname */
button,
input[type='button'],
.ti-button {
  @include mix-element-button(default);
}

.button.btnA, /* legacy! classname */
.button.red, /* legacy! classname */
.btn1, /* legacy! classname */
.ti-button.mod-primary {
  @include mix-element-button(primary);
}

.button.teal, /* legacy! classname */
.button.btnC, /* legacy! classname */
.ti-button.mod-secondary {
  @include mix-element-button(secondary);
}

.button.grey, /* legacy! classname */
.button.btnB, /* legacy! classname */
.ti-button.mod-tertiary {
  @include mix-element-button(tertiary);
}

.button.disabled, /* legacy! classname */
.button[disabled], /* legacy! classname */
button[disabled],
input[type='button'][disabled],
.ti-button.is-disabled {
  @include mix-element-button(disabled);
}

.button.mod-nostyle, /* legacy! classname */
.ti-button.mod-nostyle {
  @include mix-element-button(nostyle);
}

.button.lrg,  /*legacy! classname (TO BE SUNSET)*/
.ti-button.mod-large {
  padding: 0.75rem 1.75rem;
}

.ti-button.mod-compact {
  padding: 0.25rem 0.5rem;
}

input[type='button']:focus {
  animation: none; // kill focus animation style on input button types
}
