/**
* ==========================================================================
* _object.alert.scss
* This is the alert object
* ==========================================================================
*/

.ti_alert {
  display: flex;
  overflow: hidden;
  min-height: 7em;
  margin: 2rem 0;
  padding: 1rem calc(1.5rem - 4px);
  border-left: 4px solid $color-teal5;
  background-color: $color-grey1;
  position: relative;
}

.ti_alert > .ti_icon {
  margin-right: 1rem;
  margin-top: 2px;
}

.ti_alert.mod-primary {
  border-color: $color-teal5;

  > .ti_icon {
    fill: $color-teal5;
  }
}

.ti_alert.mod-secondary {
  border-color: $color-grey8;

  > .ti_icon {
    fill: $color-grey8;
  }
}

.ti_alert.mod-error {
  border-color: $color-red6;

  > .ti_icon {
    fill: $color-red6;
  }
}

.ti_alert.mod-warning {
  border-color: $color-yellow4;

  > .ti_icon {
    fill: $color-yellow4;
  }
}

.ti_alert.mod-success {
  border-color: $color-green;

  > .ti_icon {
    fill: $color-green;
  }
}

.ti_alert::before {
  display: none;
}

.ti_alert p,
.ti_alert ul,
.ti_alert ol {
  margin: 0 0 0.8rem 0;
}

.ti_alert ul,
.ti_alert ol {
  list-style-position: inside;
}

.ti_alert :last-child {
  margin: 0;
}

.ti_alert .ti_alert-close {
  position: absolute;
  display: block;
  top: 0.5rem;
  right: 0.5rem;
  color: $color-grey7;
  width: 2em;
  height: 2em;
  line-height: 2em;
  font-size: 1em;
  text-align: center;
  border-radius: 50%;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
