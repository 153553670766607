/**
* ==========================================================================
* _component.techdocssearch.scss
* This is the technical documents quick search component
* (eg. http://www.ti.com/power-management/technical-documents.html)
* legacy!
* todo!: improve and use updated form elements
* ==========================================================================
*/

.ti_techdocssearch {
  position: relative;
  display: inline-block;
  width: 850px;
  margin-bottom: 2rem;
  padding: 1em;
  background: $color-grey1;
  box-shadow: $global-boxShadow-1;

  ul {
    margin: 0 0 1em 1em;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 1em;
      padding: 0;
      background: none;
    }

    .slctdeslct {
      float: right;
    }

    input[type='text'] {
      max-width: 90%;
    }

    input[type='radio'] {
      margin: 3px 3px 0 5px;
    }
  }

  .leftcol {
    float: left;
    width: 400px;
    padding-right: 20px;
    border-right: 1px solid $color-grey3;
  }

  .rtcol {
    position: absolute;
    top: 100px;
    left: 420px;
    float: left;
    width: 400px;
  }

  .btm-row {
    width: 100%;
    padding: 1em;
    background: $color-grey1;

    input[type='button'] {
      position: absolute;
      right: 1em;
      bottom: 1em;
      float: right;
    }
  }

  select {
    width: 100%;
    margin: 0.2em 0;
  }
}

.ti_techdocssearch-filter {
  padding-top: 5px;

  select {
    width: 90%;
  }
}

.ti_techdocssearch-title {
  margin: 0;

  h2 {
    margin-left: 0.6em;
  }
}

.ti_techdocssearch-main {
  @include mix-clearfix();
}

.ti_techdocssearch-disclaim {
  float: right;
  margin-top: 0;
  font-size: 0.9em;
}
